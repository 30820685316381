<template>
    <kakao-map v-bind="{ ...kakaoProps, onZoomChanged, onBoundsChanged, onCenterChanged }" ref="kakao-map">
        <template slot-scope="{ on, attrs }">
            <kakao-map-overlay v-for="item in overlays" :key="item._id" v-bind="{ ...item, ...attrs }" v-on="on" />
            <kakao-map-overlay-dot v-for="item in dots" :key="item._id" v-bind="{ item, ...attrs }" v-on="on" />
        </template>
    </kakao-map>
</template>

<script>
import KakaoMap from "@/components/plugins/kakao/kakao-map.vue";
import KakaoMapOverlay from "@/components/plugins/kakao/kakao-map-overlay.vue";
import KakaoMapOverlayDot from "@/components/plugins/kakao/kakao-map-overlay-dot.vue";

import { mapMutations } from "vuex";

export default {
    components: {
        KakaoMap,
        KakaoMapOverlay,
        KakaoMapOverlayDot,
    },
    props: {
        lat: { type: Number, default: 37.4835924256371 },
        lng: { type: Number, default: 127.032693842117 },
        lvl: { type: Number, default: 3 },
        maxLevel: { type: Number, default: null },
        locations: { type: Array, default: () => [] },
        locationsWithoutReviews: { type: Array, default: () => [] },
    },
    data: () => ({
        initialSearchCommitted: false,
    }),
    computed: {
        dots() {
            // if (this.locationsWithoutReviews.length > 100) {
            //     const items = [...this.locationsWithoutReviews];

            //     const dots = [];
            //     for (let i = 0; i < 100; i++) {
            //         const index = Math.floor(Math.random() * items.length);
            //         dots.push(items[index]);
            //         items.splice(index, 1);
            //     }
            //     return dots;
            // }
            return this.locationsWithoutReviews;
        },
        kakaoProps() {
            const { lat, lng, lvl: level, maxLevel } = this;

            return { width: "100%", height: "100%", level, centerPosition: { lat, lng }, usesCustomMarker: true, maxLevel };
        },
        overlays() {
            return this.locations.map((item) => ({
                ...item,
                lat: item.geolocation.coordinates[1],
                lng: item.geolocation.coordinates[0],
            }));
        },
        query() {
            return this.$route.query;
        },
    },
    watch: {
        query({ lat, lng, _location, isSynced = "false" } = {}) {
            const searchOptions = isSynced ? { withoutRouting: true } : {};
            if (!!lat && !!_location && lat !== this.lat) {
                this.setCoordinates({ lat, lng });
                if (!(isSynced && _location)) this.$nextTick(() => this.$emit("search", searchOptions));
            } else if (!!lng && !!_location && lng !== this.lng) {
                this.setCoordinates({ lat, lng });
                if (!(isSynced && _location)) this.$nextTick(() => this.$emit("search", searchOptions));
            }
        },
    },
    methods: {
        ...mapMutations("location", ["setLvl", "setBounds", "setCoordinates"]),
        onZoomChanged() {
            const { map } = this.$refs["kakao-map"]?.$data || {};
            const lvl = map?.getLevel?.();
            this.setLvl({ lvl });
        },
        onBoundsChanged() {
            const { map } = this.$refs["kakao-map"]?.$data || {};
            const { ha: w, qa: s, oa: e, pa: n } = map?.getBounds?.();
            this.setBounds({ w, s, e, n });

            // Initial Search
            if (this.initialSearchCommitted) return;
            this.initialSearchCommitted = true;
            let { lat, lng, lvl } = this.$route.query;
            this.$nextTick(() => this.$emit("search", !!lat && !!lng && !!lvl ? { withoutRouting: true } : {}));
        },
        onCenterChanged() {
            const { map } = this.$refs["kakao-map"]?.$data || {};
            const center = map?.getCenter?.();
            this.setCoordinates({ lat: center.getLat(), lng: center.getLng() });
        },
    },
};
</script>

<style></style>
