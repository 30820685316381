<template>
    <v-tab-item value="hospitals">
        <div class="pa-20 pa-md-30">
            <div class="search-primary search-primary--gra">
                <div class="search-primary__form">
                    <v-text-field v-model="searchValue" @keydown.enter="go" outlined hide-details prepend-inner-icon="mdi-magnify" placeholder="병원명 검색" />
                </div>
            </div>
            <div class="mt-14 mt-md-20">
                <hospital-list-chips />
            </div>
        </div>

        <v-divider />
        <div class="px-20 py-8 px-md-20 py-md-10 grey-f7">
            <hospital-list-sort />
        </div>
        <v-divider />

        <div>
            <v-card flat v-if="items.length == 0">
                <div class="pa-20 px-md-30 py-md-26">
                    검색 결과가 없습니다.
                </div>
                <v-divider />
            </v-card>
            <drawer-hospital-item v-for="location in items" :key="location._id" v-bind="{ location }" />
            <div class="my-30 my-md-40" v-if="hasMore">
                <div class="v-btn--group">
                    <v-btn @click="loadMore" x-large outlined rounded color="primary" class="w-100 mw-180px">더 보기</v-btn>
                </div>
            </div>
        </div>
    </v-tab-item>
</template>

<script>
import api from "@/api";
import HospitalListSort from "./hospital-list-sort.vue";
import HospitalListChips from "./hospital-list-chips.vue";
import DrawerHospitalItem from "./drawer-hospital-item.vue";
import { mapGetters, mapState } from "vuex";
export default {
    components: {
        HospitalListSort,
        HospitalListChips,
        DrawerHospitalItem,
    },
    data: () => ({
        searchKey: "name",
        searchValue: null,

        page: 1,
        limit: 10,
        totalCount: 0,

        locations: [],
    }),
    computed: {
        ...mapState("location", ["bounds"]),
        ...mapGetters("location", ["geolocation"]),
        mode() {
            return this.$route.params.mode;
        },
        keyword() {
            return this.$route.params.keyword;
        },
        sort() {
            return this.$route.query.sort || "reviewedAt";
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        isSynced() {
            return JSON.parse(this.$route.query.isSynced || "false");
        },
        pageCount() {
            return Math.ceil(this.totalCount / this.limit) || 1;
        },
        coordinates() {
            const { lat, lng } = this.$route.query;
            return `${lat}${lng}`;
        },
        items() {
            if (this.isSynced) return this.locations.slice(0, this.page * this.limit);
            else return this.locations;
        },
        hasMore() {
            if (this.isSynced) return this.totalCount > this.items.length;
            return this.totalCount > this.locations.length;
        },
    },
    mounted() {
        if (this.keyword) this.searchValue = this.keyword;
        this.search();
    },
    watch: {
        keyword() {
            if (this.mode == "hospitals") {
                this.searchValue = this.keyword;
                this.init().then(this.search);
            }
        },
        sort() {
            if (this.mode == "hospitals") this.init().then(this.search);
        },
        mode() {
            if (this.mode == "hospitals") {
                if (!!this.searchValue && !this.keyword) {
                    this.$router.replace({ path: `/map/hospitals/${this.searchValue}` });
                }
            }
        },
        isSynced(isSynced) {
            if (this.mode == "hospitals") {
                if (isSynced) {
                    if (this.keyword?.includes?.("＃")) {
                        const { query } = this.$route;
                        this.$router.replace({ path: `/map/hospitals`, query });
                    } else this.init().then(this.search);
                } else this.init().then(this.search);
            }
        },
        coordinates() {
            if (!this.$route.query?._location) this.init().then(this.search);
        },
    },
    methods: {
        go() {
            this.setIsNotSynced();
            const { query } = this.$route;
            this.$router.push({ path: `/map/hospitals/${this.searchValue || ""}`, query });
        },
        async init() {
            this.page = 1;
            this.totalCount = 0;
            this.locations = [];
        },
        async setIsNotSynced() {
            const query = { ...this.$route.query };
            delete query.isSynced;
            this.$router.replace({ query });
        },
        async search() {
            let { skip, limit, sort, searchKey, searchValue, geolocation: near, bounds } = this;
            const regexp = /^＃([\S]+)안과/;
            if (regexp.test(searchValue)) {
                searchKey = "address";
                searchValue = searchValue.match(regexp)[1];
            }
            const headers = { skip, limit };
            if (this.isSynced) {
                delete headers.skip;
                delete headers.limit;
            }
            const params = { sort };
            if (this.isSynced) {
                if (!near || !bounds.w || !bounds.s || !bounds.e || !bounds.n) {
                    this.$nextTick(this.search);
                    return;
                }
                params.near = near;
                params.bounds = bounds;
            }
            if (!!searchValue) {
                params.searchKey = searchKey;
                params.searchValue = searchValue;
            }
            const {
                summary: { totalCount },
                locations,
            } = await api.v1.eyes.locations.gets({ headers, params });

            this.locations = this.page == 1 ? locations : this.locations.concat(locations);
            this.totalCount = totalCount;
        },
        loadMore() {
            this.page += 1;
            if (!this.isSynced) this.search();
        },
    },
};
</script>

<style></style>
