<template>
    <div>
        <div class="mb-8 mb-md-10 ellip">
            <strong class="font-size-16 font-size-md-18 grey-2--text">{{ value?.subject }}</strong>
        </div>
        <div :class="{ [className]: true, blurred: !isLoggedIn }" ref="content-box" :style="style" @click="() => (isLoggedIn ? $emit('showsDetail', true) : undefined)">
            <div v-html="value?.content" />
            <template v-for="photo in value?.photos || []">
                <img :key="photo._id" :src="photo.url" class="rounded-xl" />
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: () => ({}) },
        isLoggedIn: { type: Boolean, default: false },
        showsDetail: { type: Boolean, default: false },
    },
    computed: {
        style() {
            const { scrollHeight } = this.$refs["content-box"] || {};
            if (this.showsDetail) return `max-height:${scrollHeight}px;`;
            else return ``;
        },
        className() {
            let className = "content-box";
            if (this.showsDetail) className += " content-box--shows";
            return className;
        },
    },
};
</script>

<style lang="scss" scoped>
.content-box {
    word-wrap: break-word;
    white-space: pre-line;
}
</style>
