var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row-bar-list-wrap"
  }, [_c('v-row', {
    staticClass: "row-bar-list font-size-14"
  }, _vm._l(_vm.services, function (item) {
    var _vm$location, _vm$location$meta, _vm$location$meta$rev;
    return _c('v-col', {
      key: item.value,
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "d-flex align-center"
    }, [_c('span', {
      staticClass: "grey-2--text mr-4"
    }, [_vm._v(_vm._s(item.text))]), _c('span', {
      staticClass: "grey-a--text"
    }, [_vm._v(_vm._s(((_vm$location = _vm.location) === null || _vm$location === void 0 ? void 0 : (_vm$location$meta = _vm$location.meta) === null || _vm$location$meta === void 0 ? void 0 : (_vm$location$meta$rev = _vm$location$meta.reviewsCount) === null || _vm$location$meta$rev === void 0 ? void 0 : _vm$location$meta$rev[item.value]) || 0))])])]);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }