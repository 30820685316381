<template>
    <div>
        <div class="mb-10">
            <strong class="font-size-16 font-size-md-18 font-weight-bold grey-2--text">후기사진</strong>
        </div>
        <div>
            <div class="hospital-image-banner">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(src, index) in images" :key="src">
                            <imgs-dialog :imgs="images">
                                <template #activator="{attrs,on,setIndex}">
                                    <v-card class="rounded ma-auto" flat>
                                        <v-img v-bind="{ ...attrs, src }" v-on="on" @click="setIndex(index)" :aspect-ratio="1 / 1" />
                                    </v-card>
                                </template>
                            </imgs-dialog>
                        </div>
                    </div>
                </div>
                <div class="swiper-control">
                    <div class="swiper-prev"><i class="icon icon-chevron-left"></i></div>
                    <!-- <div class="swiper-pagination"></div> -->
                    <div class="swiper-next"><i class="icon icon-chevron-right"></i></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper/bundle";
import imgsDialog from "@/components/dumb/imgs-dialog.vue";
export default {
    components: { imgsDialog },
    props: {
        images: { type: Array, default: () => [] },
    },
    data() {
        return {
            swiper: undefined,
        };
    },
    computed: {
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.$nextTick(() => {
                this.swiper = new Swiper(".hospital-image-banner .swiper-container", {
                    observer: true,
                    observeParents: true,
                    slidesPerView: 4,
                    spaceBetween: 8,
                    // autoplay: {
                    //     delay: 5000,
                    //     disableOnInteraction: false,
                    // },
                    allowTouchMove: false,
                    speed: 500,
                    loop: false,
                    navigation: {
                        nextEl: ".hospital-image-banner .swiper-next",
                        prevEl: ".hospital-image-banner .swiper-prev",
                    },
                    // breakpoints: {
                    //     768: {
                    //         slidesPerView: 4,
                    //     },
                    // },
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
// hospital-image-banner swiper
.hospital-image-banner .swiper-container {
    overflow: visible;
}
.hospital-image-banner .swiper-slide {
    width: calc((100% / 4) - 8px);
    margin-right: 8px;
}
.hospital-image-banner .swiper-slide .v-image {
    cursor: pointer;
}
.hospital-image-banner .swiper-prev,
.hospital-image-banner .swiper-next {
    width: 32px;
    height: 32px;
}
.hospital-image-banner .swiper-prev {
    left: 10px;
}
.hospital-image-banner .swiper-next {
    right: 10px;
}
.hospital-image-banner .swiper-button-disabled {
    display: none;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}

// hospital-image-banner
.hospital-image-banner {
    position: relative;
    z-index: 0;
}

::v-deep .blurred {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    user-select: none;
}
</style>
