var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "height": "100%"
    }
  }, [_c('map-sheet-kakaomap', _vm._b({
    on: {
      "search": _vm.search
    }
  }, 'map-sheet-kakaomap', {
    lat: _vm.lat,
    lng: _vm.lng,
    lvl: _vm.lvl,
    maxLevel: 5,
    locations: _vm.clusters,
    locationsWithoutReviews: _vm.locationsWithoutReviews
  }, false)), _c('map-sheet-actions', [_c('actions-current', {
    on: {
      "search": _vm.search
    }
  }), _c('actions-area', {
    on: {
      "search": _vm.search
    }
  })], 1), _vm.showsSearch ? _c('actions-search', _vm._b({
    on: {
      "search": _vm.search
    }
  }, 'actions-search', {
    lat: _vm.lat,
    lng: _vm.lng,
    lvl: _vm.lvl,
    queryLat: _vm.queryLat,
    queryLng: _vm.queryLng,
    queryLvl: _vm.queryLvl
  }, false)) : _vm._e(), _c('svg', {
    staticStyle: {
      "width": "0",
      "height": "0",
      "position": "absolute"
    },
    attrs: {
      "aria-hidden": "true",
      "focusable": "false"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "gradient",
      "x1": "0",
      "y1": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "0%",
      "stop-color": "#999"
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#bababa"
    }
  })], 1)], 1), _c('svg', {
    staticStyle: {
      "width": "0",
      "height": "0",
      "position": "absolute"
    },
    attrs: {
      "aria-hidden": "true",
      "focusable": "false"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "gradient2",
      "x1": "0",
      "y1": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "0%",
      "stop-color": "#56beef"
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#78dcff"
    }
  })], 1)], 1), _c('svg', {
    staticStyle: {
      "width": "0",
      "height": "0",
      "position": "absolute"
    },
    attrs: {
      "aria-hidden": "true",
      "focusable": "false"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "gradient3",
      "x1": "0",
      "y1": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "0%",
      "stop-color": "#50bef0"
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#80e8ff"
    }
  })], 1)], 1), _c('svg', {
    staticStyle: {
      "width": "0",
      "height": "0",
      "position": "absolute"
    },
    attrs: {
      "aria-hidden": "true",
      "focusable": "false"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "gradient4",
      "x1": "0",
      "y1": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "0%",
      "stop-color": "#4591ff"
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#82cfff"
    }
  })], 1)], 1), _c('svg', {
    staticStyle: {
      "width": "0",
      "height": "0",
      "position": "absolute"
    },
    attrs: {
      "aria-hidden": "true",
      "focusable": "false"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "gradient5",
      "x1": "0",
      "y1": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "0%",
      "stop-color": "#2c50ff"
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#2cbaff"
    }
  })], 1)], 1), _c('pc-mode-alert')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }