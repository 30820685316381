var render = function render(){
  var _vm$value;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$value = _vm.value) !== null && _vm$value !== void 0 && _vm$value.doctor ? _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsDetail,
      expression: "showsDetail"
    }],
    staticClass: "mt-16 mt-md-20"
  }, [_c('v-row', {
    staticClass: "font-size-14",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-80px"
  }, [_c('b', {
    staticClass: "grey-2--text"
  }, [_vm._v("담당 선생님")])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "grey-7--text"
  }, [_vm._v(_vm._s(_vm.value.doctor))])])], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }