var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "map-sheet-actions-search"
  }, [_c('v-slide-x-transition', [_c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isMapRelocated,
      expression: "isMapRelocated"
    }],
    class: {
      'mb-40': _vm.isDesktop
    },
    attrs: {
      "large": "",
      "rounded": "",
      "color": "primary",
      "elevation": "6"
    },
    on: {
      "click": _vm.search
    }
  }, [_vm._v(" 이 위치에서 다시 검색 ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }