var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isCluster ? _c('div', _vm._g(_vm._b({
    staticClass: "map-pin-wrap"
  }, 'div', _vm.markerProps, false), _vm.on), [_c('div', {
    staticClass: "map-pin"
  }, [_c('div', {
    staticClass: "map-pin__inner",
    staticStyle: {
      "width": "76px"
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 389.91 512"
    }
  }, [_c('g', [_c('path', {
    style: `fill:${_vm.color};`,
    attrs: {
      "d": "M380.4,133.2c-18-58.2-65.4-105.6-123.6-123.6C236.4,3.3,215.4,0,195,0c-41.7,0-81.9,12.9-115.5,38.1C29.7,74.7,0,133.2,0,195c0,42.6,13.5,83.1,39,117l156,200,156-200c38.1-51,48.9-116.1,29.4-178.8Zm-185.4,193.8c-72.9,0-132-59.1-132-132S122.1,63,195,63s132,59.1,132,132-59.1,132-132,132Z"
    }
  }), _c('circle', {
    staticStyle: {
      "fill": "#fff"
    },
    attrs: {
      "cx": "195",
      "cy": "195",
      "r": "132"
    }
  })])]), _c('span', {
    staticClass: "map-pin__txt"
  }, [_vm._v(_vm._s(_vm.content))])])])]) : !_vm.isCluster ? _c('div', _vm._g(_vm._b({
    staticClass: "map-pin-wrap"
  }, 'div', _vm.markerProps, false), _vm.on), [_c('div', {
    staticClass: "map-pin"
  }, [_c('div', {
    staticClass: "map-pin__inner",
    staticStyle: {
      "width": "46px"
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 389.91 512"
    }
  }, [_c('g', [_c('path', {
    style: `fill:${_vm.color};`,
    attrs: {
      "d": "M380.4,133.2c-18-58.2-65.4-105.6-123.6-123.6C236.4,3.3,215.4,0,195,0c-41.7,0-81.9,12.9-115.5,38.1C29.7,74.7,0,133.2,0,195c0,42.6,13.5,83.1,39,117l156,200,156-200c38.1-51,48.9-116.1,29.4-178.8Zm-185.4,193.8c-72.9,0-132-59.1-132-132S122.1,63,195,63s132,59.1,132,132-59.1,132-132,132Z"
    }
  }), _c('circle', {
    staticStyle: {
      "fill": "#fff"
    },
    attrs: {
      "cx": "195",
      "cy": "195",
      "r": "132"
    }
  })])]), _c('span', {
    staticClass: "map-pin__txt"
  }, [_vm._v(_vm._s(_vm.content))])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }