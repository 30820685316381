var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip-group', {
    attrs: {
      "value": _vm.keyword,
      "column": ""
    }
  }, [_vm._l(_vm.items, function (item) {
    return _c('v-chip', {
      key: item,
      attrs: {
        "value": item,
        "to": `/map/hospitals/${item}`,
        "large": "",
        "outlined": "",
        "color": "grey-2"
      }
    }, [_vm._v(_vm._s(item))]);
  }), _c('v-menu', {
    attrs: {
      "content-class": "rounded shadow-primary"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return _c('v-chip', _vm._g(_vm._b({
          attrs: {
            "value": _vm.others.includes(_vm.keyword) ? _vm.keyword : undefined,
            "large": "",
            "outlined": "",
            "color": "grey-2"
          }
        }, 'v-chip', attrs, false), on), [_vm.others.includes(_vm.keyword) ? [_vm._v(_vm._s(_vm.keyword))] : [_vm._v("더 보기")], _c('v-icon', [_vm._v("mdi-menu-down")])], 2);
      }
    }])
  }, [_c('v-list', _vm._l(_vm.others, function (item) {
    return _c('v-list-item', {
      key: item,
      staticClass: "py-4",
      attrs: {
        "to": `/map/hospitals/${item}`
      }
    }, [_vm._v(_vm._s(item))]);
  }), 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }