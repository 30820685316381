<template>
    <v-menu v-model="shows" :close-on-content-click="false" rounded="lg" offset-y nudge-bottom="20" content-class="map-sheet-actions__menu shadow-primary">
        <v-btn slot="activator" slot-scope="{ attrs, on }" v-bind="attrs" v-on="on" large rounded color="red" elevation="6" class="min-w-100px">주소 선택</v-btn>
        <v-card rounded="lg" elevation="6" width="440">
            <v-card-title class="white--text justify-center pa-10" style="height:50px; background-color:#1f232f;">
                <span class="font-size-14 font-size-md-16">주소 선택</span>
                <v-btn @click="shows = false" absolute right text class=" min-w-auto px-4 py-0">
                    <v-icon color="white" class="font-size-20">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <div class="pa-20 pa-md-40">
                <actions-area-selects ref="area-selects" />

                <div class="text-center mt-20 mt-md-30">
                    <v-btn @click="select" x-large rounded color="primary" class="w-100 mw-180px">선택하기</v-btn>
                </div>
            </div>
        </v-card>
    </v-menu>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import ActionsAreaSelects from "./actions-area-selects.vue";
export default {
    components: { ActionsAreaSelects },
    data: () => ({
        shows: false,
    }),
    methods: {
        ...mapActions("location", ["getAddressLocation"]),
        ...mapMutations("location", ["setLvl"]),
        async select() {
            const { sido, gugun } = this.$refs["area-selects"]?.$data;
            const callback = () => {
                this.setLvl({ lvl: 5 });
                this.$nextTick(() => this.$emit("search"));
            };
            this.getAddressLocation({ sido, gugun, callback });
            this.shows = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.map-sheet-actions__menu {
    max-width: calc(100% - 24px);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .map-sheet-actions__menu {
        transform: translateX(-16px);
    }
}
@media (min-width: 1200px) {
}
</style>
