var render = function render(){
  var _vm$location, _vm$location2, _vm$location3, _vm$location4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-sheet', {
    staticClass: "map-drawer__inner"
  }, [_c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.location,
      expression: "!location"
    }],
    attrs: {
      "absolute": "",
      "z-index": "0",
      "color": "white",
      "light": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "100",
      "width": "5",
      "color": "primary"
    }
  })], 1)], 1), _c('v-fade-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm._location && _vm.location,
      expression: "_location && location"
    }]
  }, [_c('div', {
    staticClass: "px-20 py-16 mt-10 white d-flex justify-center",
    staticStyle: {
      "position": "sticky",
      "top": "0",
      "z-index": "1"
    }
  }, [_c('v-btn', {
    staticClass: "font-size-24 font-weight-bold text-center gret-2--text",
    attrs: {
      "text": "",
      "to": `/locations/${(_vm$location = _vm.location) === null || _vm$location === void 0 ? void 0 : _vm$location._id}`
    }
  }, [_vm._v(_vm._s((_vm$location2 = _vm.location) === null || _vm$location2 === void 0 ? void 0 : _vm$location2.name))])], 1), _c('div', {
    staticClass: "py-10 pt-md-10 pb-md-18"
  }, [_c('v-bottom-navigation', {
    staticClass: "elevation-0",
    attrs: {
      "grow": ""
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--none-active-bg",
    attrs: {
      "text": "",
      "height": "auto"
    },
    on: {
      "click": _vm.share
    }
  }, [_c('span', {
    staticClass: "font-size-14 font-size-md-16 grey-2--text pt-8"
  }, [_vm._v("공유하기")]), _c('i', {
    staticClass: "icon icon-open-in-new"
  })]), _c('v-btn', {
    staticClass: "v-btn--none-active-bg",
    attrs: {
      "text": "",
      "height": "auto",
      "href": `tel:+${(_vm$location3 = _vm.location) === null || _vm$location3 === void 0 ? void 0 : _vm$location3.phone}`,
      "disabled": !((_vm$location4 = _vm.location) !== null && _vm$location4 !== void 0 && _vm$location4.phone)
    }
  }, [_c('span', {
    staticClass: "font-size-14 font-size-md-16 grey-2--text pt-8"
  }, [_vm._v("전화하기")]), _c('i', {
    staticClass: "icon icon-phone-outline"
  })])], 1)], 1), _c('v-divider', {
    staticClass: "border-8 grey-eb"
  }), _c('v-divider', {
    staticStyle: {
      "position": "sticky",
      "top": "62px",
      "z-index": "10"
    }
  }), _c('v-tabs', {
    ref: "tabs",
    staticClass: "tab tab--underline",
    staticStyle: {
      "position": "sticky",
      "top": "62px",
      "z-index": "1"
    },
    attrs: {
      "value": _vm.$route.fullPath,
      "fixed-tabs": ""
    }
  }, [_c('v-tab', {
    attrs: {
      "to": `${_vm.$route.path}?_location=${_vm._location}&tab=content`,
      "exact": ""
    }
  }, [_c('span', [_vm._v("홈")])]), _c('v-tab', {
    attrs: {
      "to": `${_vm.$route.path}?_location=${_vm._location}&tab=reviews`,
      "exact": ""
    }
  }, [_c('span', [_vm._v("후기")])])], 1), _c('v-tabs-items', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('desc-content', _vm._b({}, 'desc-content', {
    location: _vm.location
  }, false)), _c('desc-reviews', _vm._b({}, 'desc-reviews', {
    location: _vm.location
  }, false))], 1), _vm.hasLoggedIn ? _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "large": "",
      "absolute": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "color": "primary",
      "to": `/reviews/write?_location=${_vm._location}`
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v("mdi-pencil")])], 1) : _vm._e()], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }