<template>
    <v-menu v-model="showsMenu" top offset-y v-bind="{ nudgeTop, nudgeLeft, rounded }" :close-on-content-click="false" content-class="map-overlay-menu">
        <template #activator="{attrs, on}">
            <overlay-marker ref="map-overlay" v-bind="{ attrs, on, reviewsCount, locationsCount }" />
        </template>

        <div class="map-overlay-menu__inner">
            <v-list v-if="locationsCount > 1">
                <v-list-item v-for="location in locations" :key="location._id" @click="setLocation(location)" class="py-4">
                    <v-list-item-title class="font-size-14 text-truncate">{{ location.name }}</v-list-item-title>
                </v-list-item>
            </v-list>

            <v-card v-else elevation="6" class="white pa-20">
                <div class="mb-10">
                    <v-row no-gutters align="center" class="row--x-small">
                        <v-col cols="8">
                            <div class="font-size-18 font-weight-bold text-truncate">{{ locations[0].name }}</div>
                        </v-col>
                        <v-col cols="4">
                            <v-btn text color="primary" @click="setLocation(locations[0])" class="h-auto pa-2 mr-n4"> 더 보기 <v-icon small>mdi-chevron-right</v-icon> </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <div>
                    <v-tooltip bottom nudge-bottom="-10px">
                        <template #activator="{attrs, on}">
                            <div v-bind="attrs" v-on="on">
                                <div class="font-size-16 grey-7--text text-truncate">{{ locations[0].address1 }} {{ locations[0].address2 }}</div>
                            </div>
                        </template>
                        <span>{{ locations[0].address1 }} {{ locations[0].address2 }}</span>
                    </v-tooltip>
                </div>
                <div class="mt-2">
                    <v-row no-gutters align="center" class="row--x-small">
                        <v-col>
                            <span class="font-size-14 grey-2--text">
                                후기 <span class="grey-a--text">{{ locations[0]._reviews.length }}</span>
                            </span>
                        </v-col>
                        <v-col cols="auto">
                            <v-rating :value="(locations[0]?.stars || 0) / 10" readonly half-increments dense size="14" color="warning" background-color="warning" />
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>
    </v-menu>
</template>

<script>
import overlayMarker from "./overlay-marker.vue";
export default {
    components: { overlayMarker },
    props: {
        lat: { type: Number, default: null },
        lng: { type: Number, default: null },
        address: { type: String, default: null },
        locations: { type: Array, default: () => [] },
    },
    data() {
        return {
            overay: null,
            showsMenu: false,
        };
    },
    computed: {
        map() {
            return this.$parent.map;
        },
        content() {
            return this.$refs["map-overlay"]?.$el;
        },
        locationsCount() {
            return this.locations.length;
        },
        reviewsCount() {
            return this.locations.reduce((sum, { _reviews }) => sum + (_reviews?.length || 0), 0);
        },
        rounded() {
            if (this.locationsCount > 1) return "lg";
            else return "lg";
        },
        nudgeTop() {
            // if (this.locationsCount > 1) return 102;
            // else return 70;
        },
        nudgeLeft() {
            if (this.locationsCount > 1) return 72;
            else return 86;
        },
        isOn() {
            return (this.locations || []).some(({ _id }) => _id == this.$route.query?._location);
        },
    },
    watch: {
        lat() {
            this.init();
        },
        lng() {
            this.init();
        },
        address() {
            this.marking();
        },
        isOn(isOn) {
            if (isOn) this.$nextTick(() => setTimeout(() => (this.showsMenu = true), 500));
        },
    },
    destroyed() {
        this.overlay.setMap(null);
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            // 기존 오버레이 제거
            if (this.overlay) {
                this.overlay.setMap(null);
            }

            this.$nextTick(() => {
                const { content } = this;

                // 위도 & 경도로 오버레이 생성
                if (this.lat && this.lng) {
                    this.position = new kakao.maps.LatLng(this.lat, this.lng);
                    this.overlay = new kakao.maps.CustomOverlay({ position: this.position, content });
                }

                // 주소로 오버레이 생성
                else if (this.address) {
                    this.$parent.geocoder.addressSearch(this.address, (result, status) => {
                        // 정상적으로 검색이 완료됐으면
                        if (status === kakao.maps.services.Status.OK) {
                            this.position = new kakao.maps.LatLng(result[0].y, result[0].x);
                            this.overlay = new kakao.maps.CustomOverlay({ position: this.position, content });
                        }
                    });
                }

                this.overlay.setMap(this.map);
                if (this.isOn) this.$nextTick(() => setTimeout(() => (this.showsMenu = true), 500));
            });
        },
        setLocation({ _id: _location }) {
            this.$router.push({ query: { ...this.$route.query, _location, tab: "content" } });
        },
    },
};
</script>

<style scoped>
.map-overlay-menu {
    width: 220px;
    contain: none;
    will-change: auto;
    padding-bottom: 20px;
    overflow: visible;
    box-shadow: none !important;
}
.map-overlay-menu::before {
    content: "";
    position: absolute;
    bottom: 8px;
    left: 50%;
    z-index: 1;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    display: block;
    border-top: 12px solid #fff;
    border-bottom: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
}
.map-overlay-menu__inner {
    height: 126px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 0 30px rgba(17, 41, 81, 0.2) !important;
}
.map-overlay-menu__inner > .v-card,
.map-overlay-menu__inner > .v-list {
    height: 100%;
}
</style>
