<template>
    <v-btn small fab color="white" elevation="6" class="mr-6 mr-md-10" @click="setCurrent">
        <i class="icon icon-crosshairs"></i>
    </v-btn>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    methods: {
        ...mapActions("location", ["getCurrentLocation"]),
        async setCurrent() {
            try {
                this.getCurrentLocation().then(() => this.$emit("search"));
            } catch (error) {
                console.error(error);
                alert("위치 권한을 허용하신 뒤 다시 시도하십시오");
            }
        },
    },
};
</script>

<style></style>
