var render = function render(){
  var _vm$item, _vm$item4, _vm$item5, _vm$item6, _vm$item6$_reviews, _vm$item7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', _vm._b({
    attrs: {
      "top": "",
      "offset-y": "",
      "close-on-content-click": false,
      "content-class": "map-overlay-menu"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._b({
          ref: "map-overlay",
          attrs: {
            "width": "2",
            "height": "2",
            "fab": "",
            "ripple": false,
            "text": ""
          }
        }, 'v-btn', attrs, false), [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "fab": ""
          }
        }, 'v-btn', _vm.btnAttrs, false), on))], 1)];
      }
    }]),
    model: {
      value: _vm.showsMenu,
      callback: function ($$v) {
        _vm.showsMenu = $$v;
      },
      expression: "showsMenu"
    }
  }, 'v-menu', {
    nudgeLeft: _vm.nudgeLeft
  }, false), [_c('div', {
    staticClass: "map-overlay-menu__inner"
  }, [_c('v-card', {
    staticClass: "white pa-20",
    attrs: {
      "elevation": "6"
    }
  }, [_c('div', {
    staticClass: "mb-10"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('div', {
    staticClass: "font-size-18 font-weight-bold text-truncate"
  }, [_vm._v(_vm._s((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.name))])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-btn', {
    staticClass: "h-auto pa-2 mr-n4",
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.setLocation(_vm.item);
      }
    }
  }, [_vm._v(" 더 보기 "), _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1), _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "nudge-bottom": "-10px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var _vm$item2, _vm$item3;
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('div', {
          staticClass: "font-size-16 grey-7--text text-truncate"
        }, [_vm._v(_vm._s((_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : _vm$item2.address1) + " " + _vm._s((_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.address2))])])];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.address1) + " " + _vm._s((_vm$item5 = _vm.item) === null || _vm$item5 === void 0 ? void 0 : _vm$item5.address2))])])], 1), _c('div', {
    staticClass: "mt-2"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('span', {
    staticClass: "font-size-14 grey-2--text"
  }, [_vm._v(" 후기 "), _c('span', {
    staticClass: "grey-a--text"
  }, [_vm._v(_vm._s((_vm$item6 = _vm.item) === null || _vm$item6 === void 0 ? void 0 : (_vm$item6$_reviews = _vm$item6._reviews) === null || _vm$item6$_reviews === void 0 ? void 0 : _vm$item6$_reviews.length))])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-rating', {
    attrs: {
      "value": (((_vm$item7 = _vm.item) === null || _vm$item7 === void 0 ? void 0 : _vm$item7.stars) || 0) / 10,
      "readonly": "",
      "half-increments": "",
      "dense": "",
      "size": "14",
      "color": "warning",
      "background-color": "warning"
    }
  })], 1)], 1)], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }