<template>
    <div>
        <div class="px-20 py-16 px-md-24 py-md-20">
            <v-row no-gutters align="center">
                <v-col cols="auto">
                    <strong class="font-size-16 font-size-md-18 grey-2--text"
                        >후기 <span class="primary--text">{{ reviewsLength }}</span></strong
                    >
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <hospital-reviews-sort class="mr-n12" />
                </v-col>
            </v-row>
        </div>
        <v-divider />

        <hospital-reviews-item v-for="review in reviews" :key="review._id" :value="review" />

        <div class="mt-30 mt-md-40" v-if="hasMore">
            <div class="v-btn--group">
                <v-btn @click="loadMore" x-large outlined rounded color="primary" class="w-100 mw-180px">더 보기</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import hospitalReviewsSort from "./hospital-reviews-sort.vue";
import HospitalReviewsItem from "./hospital-reviews-item.vue";
export default {
    components: { hospitalReviewsSort, HospitalReviewsItem },
    props: {
        location: { type: Object, default: () => {} },
    },
    data: () => ({
        reviews: [],
        page: 1,
        limit: 10,
        totalCount: 0,
    }),
    computed: {
        reviewsLength() {
            return this.location?._reviews?.length;
        },
        _location() {
            return this.location?._id;
        },
        sort() {
            return this.$route.query.sortReviews || "createdAt";
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.totalCount / this.limit) || 1;
        },
        hasMore() {
            return this.totalCount > this.reviews.length;
        },
    },
    mounted() {
        if (!!this._location) this.sync().then(this.search);
    },
    watch: {
        _location(value) {
            if (!!value) this.sync().then(this.search);
        },
        sort() {
            if (!!this._location) this.sync().then(this.search);
        },
    },
    methods: {
        async sync() {
            this.page = this.$route.query.page || 1;
            this.reviews = [];
        },
        async search() {
            const { _location, skip, limit, sort } = this;
            const {
                summary: { totalCount },
                reviews,
            } = await api.v1.eyes.reviews.gets({
                headers: { skip, limit },
                params: { _location, sort },
            });
            this.totalCount = totalCount;
            this.reviews = this.reviews.concat(reviews);
        },
        loadMore() {
            this.page += 1;
            this.search();
        },
    },
};
</script>

<style></style>
