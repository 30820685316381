<template>
    <div class="map-sheet-actions-search">
        <v-slide-x-transition>
            <v-btn v-show="isMapRelocated" large rounded color="primary" elevation="6" :class="{ 'mb-40': isDesktop }" @click="search">
                이 위치에서 다시 검색
            </v-btn>
        </v-slide-x-transition>
    </div>
</template>

<script>
import detect from "@/plugins/detect";
export default {
    props: {
        lat: { type: Number, default: null },
        lng: { type: Number, default: null },
        lvl: { type: Number, default: null },
        queryLat: { type: Number, default: null },
        queryLng: { type: Number, default: null },
        queryLvl: { type: Number, default: null },
    },
    data: () => ({
        detect,
    }),
    computed: {
        isDesktop() {
            return detect == "pc";
        },
        isMapRelocated() {
            const latNotSame = this.queryLat != this.lat;
            const lngNotSame = this.queryLng != this.lng;
            const lvlNotSame = this.queryLvl != this.lvl;
            if (latNotSame || lngNotSame || lvlNotSame) return true;
            else return false;
        },
    },
    methods: {
        search() {
            this.$emit("search", { isSynced: true });
        },
    },
};
</script>

<style lang="scss" scoped>
.map-sheet-actions-search {
    position: absolute;
    top: 70vh;
    right: 50%;
    transform: translateX(50%) translateY(-150%);
    z-index: 1;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .map-sheet-actions-search {
        top: 100%;
    }
}
@media (min-width: 1200px) {
}
</style>
