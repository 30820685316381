var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    staticClass: "mr-6 mr-md-10",
    attrs: {
      "small": "",
      "fab": "",
      "color": "white",
      "elevation": "6"
    },
    on: {
      "click": _vm.setCurrent
    }
  }, [_c('i', {
    staticClass: "icon icon-crosshairs"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }