<template>
    <div class="map-sheet-actions">
        <slot />
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.map-sheet-actions {
    position: fixed;
    top: calc(var(--header-body) + 10px);
    right: 10px;
    z-index: 1;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .map-sheet-actions {
        top: calc(var(--header-body) + 30px);
        right: 30px;
    }
}
@media (min-width: 1200px) {
}
</style>
