<template>
    <v-tab-item value="content">
        <div class="px-20 py-16 px-md-24 py-md-20">
            <v-row align="center" class="row--small">
                <v-col cols="12">
                    <div class="d-flex align-center">
                        <span class="mr-10"><i class="icon icon-phone-outline-sm"></i></span>
                        <span class="font-size-14 font-size-md-16 grey-2--text">{{ location?.phone }}</span>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="d-flex align-center">
                        <span class="mr-10"><i class="icon icon-map-marker-outline-sm" style="width:16px;"></i></span>
                        <span class="font-size-14 font-size-md-16 grey-2--text">{{ location?.address }}</span>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="d-flex align-center">
                        <span class="mr-10"><i class="icon icon-home-outline-sm"></i></span>
                        <a :href="location?.homepage" target="_blank" class="font-size-14 font-size-md-16 primary--text">{{ location?.homepage }}</a>
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-divider />
        <div class="px-20 py-16 px-md-24 py-md-20">
            <v-row align="center" justify="space-between" class="row--small">
                <v-col cols="auto">
                    <div class="d-flex align-center">
                        <span class="mr-10"><i class="icon icon-comment-sm"></i></span>
                        <span class="font-size-14 font-size-md-16 grey-2--text mr-6">후기</span>
                        <span class="font-size-14 font-size-md-16 grey-a--text">{{ location?._reviews?.length }}개</span>
                    </div>
                </v-col>
                <v-col cols="auto">
                    <v-rating :value="(location?.meta?.starsAverage?.total || 0) / 10" readonly half-increments color="warning" background-color="warning" dense />
                </v-col>
            </v-row>
        </div>

        <v-divider />
        <div class="px-20 py-16 px-md-24 py-md-20">
            <hospital-services v-bind="{ location }" />
        </div>

        <template v-if="images?.length > 0">
            <v-divider class="border-8 grey-eb" />
            <div class="px-20 py-16 px-md-24 py-md-20 hidden">
                <hospital-images v-bind="{ images }" />
            </div>
        </template>

        <v-divider class="border-8 grey-eb" />
        <hospital-reviews v-bind="{ location }" />
    </v-tab-item>
</template>

<script>
import HospitalImages from "./hospital-images.vue";
import HospitalReviews from "./hospital-reviews.vue";
import HospitalServices from "./hospital-services.vue";
export default {
    components: {
        HospitalImages,
        HospitalReviews,
        HospitalServices,
    },
    props: {
        location: { type: Object, default: () => ({}) },
    },
    computed: {
        images() {
            return this.location?.images;
        },
    },
};
</script>

<style></style>
