<template>
    <v-navigation-drawer :value="true" app hide-overlay mobile-breakpoint="1200" :width="width" :class="{'map-drawer--shows' : showsDesc}" class="map-drawer">
        <!-- S:상세 -->
        <drawer-hospital-desc :class="{'map-drawer__content--shows' : showsDesc}" class="map-drawer__content map-drawer__content-detail" />
        <!-- E:상세 -->

        <!-- S:목록 -->
        <v-sheet class="map-drawer__content">
            <div class="map-drawer__inner">
                <v-tabs fixed-tabs class="tab">
                    <v-tab :to="`/map/hospitals`">병원목록</v-tab>
                    <v-tab :to="`/map/reviews`">후기목록</v-tab>
                </v-tabs>
                <v-tabs-items :value="mode">
                    <drawer-hospital-list />
                    <drawer-review-list />
                </v-tabs-items>
            </div>
        </v-sheet>
        <!-- E:목록 -->

        <!-- S:buttons -->
        <v-fade-transition>
            <v-btn v-show="showsDesc && showsList" @click="hideDesc" small fab color="primary" class="drawer-btn drawer-btn__close">
                <v-icon color="white">mdi-close</v-icon>
            </v-btn>
        </v-fade-transition>
        <v-btn @click="showsList = !showsList" fab color="white" width="24" class="drawer-btn drawer-btn__toggle">
            <v-icon color="grey-7">mdi-chevron-left</v-icon>
        </v-btn>
        <!-- E:buttons -->
    </v-navigation-drawer>
</template>

<script>
import DrawerReviewList from "./drawer-review-list.vue";
import DrawerHospitalList from "./drawer-hospital-list.vue";
import DrawerHospitalDesc from "./drawer-hospital-desc.vue";
export default {
    components: {
        DrawerReviewList,
        DrawerHospitalList,
        DrawerHospitalDesc,
    },
    data: () => ({
        showsList: true,
        showsDesc: false,
    }),
    computed: {
        mode() {
            return this.$route.params.mode;
        },
        width() {
            if (this.showsList && this.showsDesc) return 860;
            if (this.showsList) return 430;
            return 0;
        },
        _location() {
            return this.$route.query._location;
        },
    },
    mounted() {
        if (this._location) this.showsDesc = true;
    },
    watch: {
        _location() {
            if (this._location) this.showsDesc = true;
            if (!this._location) this.showsDesc = false;
        },
    },
    methods: {
        hideDesc() {
            this.$router.push({ query: { ...this.$route.query, _location: undefined, _tab: undefined } });
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
