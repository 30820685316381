var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('kakao-map', _vm._b({
    ref: "kakao-map",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_vm._l(_vm.overlays, function (item) {
          return _c('kakao-map-overlay', _vm._g(_vm._b({
            key: item._id
          }, 'kakao-map-overlay', Object.assign({}, item, attrs), false), on));
        }), _vm._l(_vm.dots, function (item) {
          return _c('kakao-map-overlay-dot', _vm._g(_vm._b({
            key: item._id
          }, 'kakao-map-overlay-dot', Object.assign({
            item
          }, attrs), false), on));
        })];
      }
    }])
  }, 'kakao-map', Object.assign({}, _vm.kakaoProps, {
    onZoomChanged: _vm.onZoomChanged,
    onBoundsChanged: _vm.onBoundsChanged,
    onCenterChanged: _vm.onCenterChanged
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }