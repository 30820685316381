var render = function render(){
  var _vm$images;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": "reviews"
    }
  }, [_c('div', {
    staticClass: "px-20 py-16 px-md-24 py-md-20"
  }, [_c('hospital-services', _vm._b({}, 'hospital-services', {
    location: _vm.location
  }, false))], 1), ((_vm$images = _vm.images) === null || _vm$images === void 0 ? void 0 : _vm$images.length) > 0 ? [_c('v-divider', {
    staticClass: "border-8 grey-eb"
  }), _c('div', {
    staticClass: "px-20 py-16 px-md-24 py-md-20 hidden"
  }, [_c('hospital-images', _vm._b({}, 'hospital-images', {
    images: _vm.images
  }, false))], 1)] : _vm._e(), _c('v-divider', {
    staticClass: "border-8 grey-eb"
  }), _c('hospital-reviews', _vm._b({}, 'hospital-reviews', {
    location: _vm.location
  }, false))], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }