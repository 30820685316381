<template>
    <v-sheet v-if="isBrowserOnPcMode" class="map-scheet-pc-mode-alert-sheet d-flex align-center justify-center" color="transparent" v-touch="{ start }"></v-sheet>
</template>

<script>
export default {
    computed: {
        isBrowserOnPcMode() {
            let isOnAndroid = navigator.userAgent.toLowerCase().includes("linux");
            let isOnPcMode = !navigator.userAgent.toLowerCase().includes("mobile");
            return isOnAndroid && isOnPcMode;
        },
    },
    methods: {
        alertUnsetPcMode() {
            alert('PC모드로 접속중입니다. 지도를 조작할 수 없습니다. 지도를 조작하기 위해서는 다음과 같이 브라우저 설정을 변경 해주셔야 합니다.\n\n"PC모드" 또는 "데스크톱 사이트" 설정 해제');
        },
        start() {
            this.alertUnsetPcMode();
        },
    },
};
</script>

<style lang="scss" scoped>
.map-scheet-pc-mode-alert-sheet {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
</style>
