var render = function render(){
  var _vm$location, _vm$location2, _vm$location3, _vm$location3$_review;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    },
    on: {
      "click": _vm.toggleItem
    }
  }, [_c('div', {
    staticClass: "pa-20 px-md-30 py-md-26"
  }, [_c('div', {
    staticClass: "font-size-20 font-size-md-24 font-weight-bold grey-2--text mb-8"
  }, [_vm._v(_vm._s((_vm$location = _vm.location) === null || _vm$location === void 0 ? void 0 : _vm$location.name))]), _c('div', {
    staticClass: "font-size-14 font-size-md-16 grey-7--text ellip mb-6"
  }, [_vm._v(_vm._s((_vm$location2 = _vm.location) === null || _vm$location2 === void 0 ? void 0 : _vm$location2.address))]), _c('div', {
    staticClass: "row-bar-list-wrap"
  }, [_c('v-row', {
    staticClass: "row-bar-list font-size-14"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "grey-2--text mr-4"
  }, [_vm._v("후기")]), _c('span', {
    staticClass: "grey-a--text"
  }, [_vm._v(_vm._s(((_vm$location3 = _vm.location) === null || _vm$location3 === void 0 ? void 0 : (_vm$location3$_review = _vm$location3._reviews) === null || _vm$location3$_review === void 0 ? void 0 : _vm$location3$_review.length) || 0))])])])], 1)], 1)]), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }