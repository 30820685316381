<template>
    <div v-if="isCluster" v-bind="markerProps" v-on="on" class="map-pin-wrap">
        <div class="map-pin">
            <div class="map-pin__inner" style="width: 76px;">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 389.91 512">
                    <g>
                        <path :style="`fill:${color};`" d="M380.4,133.2c-18-58.2-65.4-105.6-123.6-123.6C236.4,3.3,215.4,0,195,0c-41.7,0-81.9,12.9-115.5,38.1C29.7,74.7,0,133.2,0,195c0,42.6,13.5,83.1,39,117l156,200,156-200c38.1-51,48.9-116.1,29.4-178.8Zm-185.4,193.8c-72.9,0-132-59.1-132-132S122.1,63,195,63s132,59.1,132,132-59.1,132-132,132Z" />
                        <circle style="fill:#fff;" cx="195" cy="195" r="132"></circle>
                    </g>
                </svg>
                <span class="map-pin__txt">{{ content }}</span>
            </div>
        </div>
    </div>
    <div v-else-if="!isCluster" v-bind="markerProps" v-on="on" class="map-pin-wrap">
        <div class="map-pin">
            <div class="map-pin__inner" style="width: 46px;">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 389.91 512">
                    <g>
                        <path :style="`fill:${color};`" d="M380.4,133.2c-18-58.2-65.4-105.6-123.6-123.6C236.4,3.3,215.4,0,195,0c-41.7,0-81.9,12.9-115.5,38.1C29.7,74.7,0,133.2,0,195c0,42.6,13.5,83.1,39,117l156,200,156-200c38.1-51,48.9-116.1,29.4-178.8Zm-185.4,193.8c-72.9,0-132-59.1-132-132S122.1,63,195,63s132,59.1,132,132-59.1,132-132,132Z" />
                        <circle style="fill:#fff;" cx="195" cy="195" r="132"></circle>
                    </g>
                </svg>
                <span class="map-pin__txt">{{ content }}</span>
            </div>
        </div>
    </div>
    <!-- <v-btn fab v-bind="markerProps" v-on="on">
        {{ content }}
    </v-btn> -->
</template>

<script>
export default {
    props: {
        on: { type: Object, default: () => ({}) },
        attrs: { type: Object, default: () => ({}) },
        locationsCount: { type: Number, default: 0 },
        reviewsCount: { type: Number, defatul: 0 },
    },
    computed: {
        content() {
            // return "999+";
            if (this.reviewsCount > 999) return "999+";
            else return this.reviewsCount;
        },
        color() {
            if (this.locationsCount > 1) {
                if (this.reviewsCount == 0) return "url(#gradient) #999";
                if (0 < this.reviewsCount < 10) return "url(#gradient2) #56beef";
                if (10 < this.reviewsCount < 50) return "url(#gradient3) #50bef0";
                if (50 < this.reviewsCount < 100) return "url(#gradient4) #4591ff";
                if (100 < this.reviewsCount) return "url(#gradient5) #2c50ff";
            } else {
                if (this.reviewsCount == 0) return "#999";
                if (0 < this.reviewsCount < 10) return "#78dcff";
                if (10 < this.reviewsCount < 50) return "#50bef0";
                if (50 < this.reviewsCount < 100) return "#4591ff";
                if (100 < this.reviewsCount) return "#2c50ff";
            }
        },
        isCluster() {
            return this.locationsCount > 1;
        },
        markerProps() {
            const { color } = this;
            return { ...this.attrs, color };
        },
    },
};
</script>

<style>
.map-pin-wrap {
    transform: translate(0, -50%);
}
.map-pin {
    padding-bottom: 25%;
    line-height: 0;
}
.map-pin__inner {
    position: relative;
    line-height: 0;
}
.map-pin__inner svg {
    margin-bottom: -25%;
}
.map-pin path {
    fill: #999;
}
.map-pin__txt {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, calc(-50% - 1px));
    -ms-transform: translate(-50%, calc(-50% - 1px));
    transform: translate(-50%, calc(-50% - 1px));
    transform-origin: center;
    z-index: 1;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    color: #222;
}
</style>
