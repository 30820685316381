var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--map",
    attrs: {
      "hideFooter": "",
      "isMap": ""
    }
  }, [_c('map-sheet', {
    staticClass: "map-content",
    attrs: {
      "slot": "map"
    },
    slot: "map"
  }), _c('map-drawer', {
    attrs: {
      "slot": "drawer"
    },
    slot: "drawer"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }