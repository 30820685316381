<template>
    <v-chip-group :value="keyword" column>
        <v-chip v-for="item in items" :key="item" :value="item" :to="`/map/reviews/${item}`" large outlined color="grey-2">{{ item }}</v-chip>
    </v-chip-group>
</template>

<script>
import { SERVICES } from "@/assets/variables";
const services = Object.values(SERVICES).map(({ text }) => `＃${text}`);
export default {
    computed: {
        keyword() {
            return this.$route.params.keyword;
        },
        items() {
            return services;
        },
    },
};
</script>
