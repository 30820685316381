<template>
    <v-chip-group :value="keyword" column>
        <v-chip v-for="item in items" :key="item" :value="item" :to="`/map/hospitals/${item}`" large outlined color="grey-2">{{ item }}</v-chip>
        <v-menu content-class="rounded shadow-primary">
            <v-chip slot="activator" slot-scope="{ attrs, on }" v-bind="attrs" v-on="on" :value="others.includes(keyword) ? keyword : undefined" large outlined color="grey-2">
                <template v-if="others.includes(keyword)">{{ keyword }}</template>
                <template v-else>더 보기</template>
                <v-icon>mdi-menu-down</v-icon>
            </v-chip>
            <v-list>
                <v-list-item v-for="item in others" :key="item" :to="`/map/hospitals/${item}`" class="py-4">{{ item }}</v-list-item>
            </v-list>
        </v-menu>
    </v-chip-group>
</template>

<script>
const tags = ["＃강남안과", "＃부산안과", "＃광주안과", "＃인천안과", "＃대구안과", "＃서울안과", "＃대전안과", "＃울산안과", "＃세종안과", "＃경기안과", "＃강원안과", "＃충북안과", "＃충남안과", "＃전북안과", "＃전남안과", "＃경북안과", "＃경남안과", "＃제주안과"];
export default {
    computed: {
        keyword() {
            return this.$route.params.keyword;
        },
        items() {
            return [tags[0], tags[1], tags[2], tags[3], tags[4]];
        },
        others() {
            const [tag0, tag1, tag2, tag3, tag4, ...others] = tags;
            return others;
        },
    },
};
</script>
