<template>
    <div>
        <div class="px-20 py-16 px-md-24 py-md-30" style="position:relative">
            <div class="mb-8 mb-md-18">
                <reviews-item-chips v-bind="{ value }" />
            </div>

            <div class="mb-6 mb-md-12">
                <reviews-item-basic v-bind="{ value, commentsLength }" />
            </div>

            <reviews-item-texts v-bind="{ value, showsDetail, isLoggedIn }" @showsDetail="(bool) => (showsDetail = bool)" />
            <div :class="{ blurred: !isLoggedIn }">
                <reviews-item-stars v-bind="{ value, showsDetail }" />
                <reviews-item-doctor v-bind="{ value, showsDetail }" />
                <reviews-item-charges v-bind="{ value, showsDetail }" />
            </div>

            <div class="mt-20 mt-md-30">
                <review-item-comments v-if="showsDetail && _review" v-bind="{ review: value, _review }" @syncComments="(num) => (commentsLength = num)" />
            </div>
            <v-btn v-if="!isLoggedIn" dark color="grey" absolute style="right:50%;bottom:2rem;transform:translateX(50%);" to="/login">로그인 후 확인하기</v-btn>
        </div>
        <v-divider />
    </div>
</template>

<script>
import ReviewsItemChips from "./reviews-item-chips.vue";
import ReviewsItemBasic from "./reviews-item-basic.vue";
import ReviewsItemTexts from "./reviews-item-texts.vue";
import ReviewsItemStars from "./reviews-item-stars.vue";
import ReviewsItemDoctor from "./reviews-item-doctor.vue";
import ReviewsItemCharges from "./reviews-item-charges.vue";
import ReviewItemComments from "../reviews/review-item-comments.vue";
export default {
    components: {
        ReviewsItemChips,
        ReviewsItemBasic,
        ReviewsItemTexts,
        ReviewsItemStars,
        ReviewsItemDoctor,
        ReviewsItemCharges,
        ReviewItemComments,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // review
    },
    data: () => ({
        showsDetail: false,
        commentsLength: 0,
    }),
    computed: {
        _review() {
            return this.value?._id;
        },
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        "value._comments.length"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.commentsLength = this.value?._comments?.length || 0;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .blurred {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    user-select: none;
}
</style>
