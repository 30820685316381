var render = function render(){
  var _vm$review, _vm$review$writer, _vm$review2, _vm$review2$meta, _vm$review3, _vm$review3$_comments, _vm$review$location, _vm$review$location2, _vm$review4, _vm$review4$stars;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    },
    on: {
      "click": _vm.toggleItem
    }
  }, [_c('div', {
    staticClass: "pa-20 px-md-30 py-md-26"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "font-size-20 font-size-md-24 font-weight-bold primary--text mb-8"
  }, [_vm._v(_vm._s(_vm.serviceName))]), _c('div', {
    staticClass: "font-size-14 grey-a--text mb-8"
  }, [_vm._v(_vm._s((_vm$review = _vm.review) === null || _vm$review === void 0 ? void 0 : (_vm$review$writer = _vm$review.writer) === null || _vm$review$writer === void 0 ? void 0 : _vm$review$writer.nickname) + "님")]), _c('div', [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('i', {
    staticClass: "icon icon-heart-outline-grey"
  }), _c('span', {
    staticClass: "font-size-14 grey-7--text ml-4"
  }, [_vm._v(_vm._s(((_vm$review2 = _vm.review) === null || _vm$review2 === void 0 ? void 0 : (_vm$review2$meta = _vm$review2.meta) === null || _vm$review2$meta === void 0 ? void 0 : _vm$review2$meta.likes) || 0))])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('i', {
    staticClass: "icon icon-comment-outline-grey"
  }), _c('span', {
    staticClass: "font-size-14 grey-7--text ml-4"
  }, [_vm._v(_vm._s(((_vm$review3 = _vm.review) === null || _vm$review3 === void 0 ? void 0 : (_vm$review3$_comments = _vm$review3._comments) === null || _vm$review3$_comments === void 0 ? void 0 : _vm$review3$_comments.length) || 0))])])])], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('imgs-dialog', {
    attrs: {
      "imgs": _vm.images,
      "disabled": !_vm.isLoggedIn
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var _vm$images, _vm$images2;
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-img', _vm._g(_vm._b({
          staticClass: "rounded",
          staticStyle: {
            "overflow": "hidden"
          },
          attrs: {
            "src": (_vm$images = _vm.images) === null || _vm$images === void 0 ? void 0 : _vm$images[0],
            "width": "90",
            "aspect-ratio": 1 / 1,
            "content-class": "d-flex justify-end align-end"
          }
        }, 'v-img', attrs, false), on), [_c('v-overlay', {
          attrs: {
            "slot": "placeholder",
            "absolute": "",
            "z-index": "0",
            "color": "grey lighten-2"
          },
          slot: "placeholder"
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "grey"
          }
        }, [_vm._v("mdi-image-off-outline")])], 1), _c('v-card', {
          staticClass: "d-flex align-center justify-center",
          staticStyle: {
            "border-radius": "10px 0px !important"
          },
          attrs: {
            "width": "26",
            "height": "26",
            "tile": "",
            "dark": "",
            "color": "rgba(0,0,0,0.5)"
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-light"
        }, [_vm._v(_vm._s((_vm$images2 = _vm.images) === null || _vm$images2 === void 0 ? void 0 : _vm$images2.length))])])], 1)];
      }
    }])
  })], 1)], 1), _c('div', {
    staticClass: "mt-10 mt-md-18"
  }, [_c('div', {
    staticClass: "mb-6 mb-md-8 ellip"
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-18 grey-2--text"
  }, [_vm._v(_vm._s(_vm.review.subject))])]), _c('div', {
    class: {
      'font-size-14 font-size-md-16 grey-7--text ellipsis': true,
      blurred: !_vm.isLoggedIn
    }
  }, [_vm._v(_vm._s(_vm.content))])]), _c('div', {
    staticClass: "mt-10 mt-md-14"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "font-size-14 grey-a--text"
  }, [_vm._v(_vm._s((_vm$review$location = _vm.review.location) === null || _vm$review$location === void 0 ? void 0 : _vm$review$location.areaName))]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "font-size-14 font-size-md-16 font-weight-bold grey-2--text"
  }, [_vm._v(_vm._s((_vm$review$location2 = _vm.review.location) === null || _vm$review$location2 === void 0 ? void 0 : _vm$review$location2.name))]), _c('v-icon', {
    staticClass: "font-size-md-18 grey-2--text mt-n2 ml-4"
  }, [_vm._v("mdi-chevron-right")])], 1)]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-rating', {
    attrs: {
      "value": (_vm$review4 = _vm.review) === null || _vm$review4 === void 0 ? void 0 : (_vm$review4$stars = _vm$review4.stars) === null || _vm$review4$stars === void 0 ? void 0 : _vm$review4$stars.total,
      "readonly": "",
      "half-increments": "",
      "dense": "",
      "color": "warning",
      "background-color": "warning"
    }
  })], 1)], 1)], 1)], 1), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }