var render = function render(){
  var _vm$value, _class, _vm$value2, _vm$value3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-8 mb-md-10 ellip"
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-18 grey-2--text"
  }, [_vm._v(_vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.subject))])]), _c('div', {
    ref: "content-box",
    class: (_class = {}, _class[_vm.className] = true, _class.blurred = !_vm.isLoggedIn, _class),
    style: _vm.style,
    on: {
      "click": function () {
        return _vm.isLoggedIn ? _vm.$emit('showsDetail', true) : undefined;
      }
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.content)
    }
  }), _vm._l(((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.photos) || [], function (photo) {
    return [_c('img', {
      key: photo._id,
      staticClass: "rounded-xl",
      attrs: {
        "src": photo.url
      }
    })];
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }