<template>
    <v-card @click="toggleItem" flat>
        <div class="pa-20 px-md-30 py-md-26">
            <div class="font-size-20 font-size-md-24 font-weight-bold grey-2--text mb-8">{{ location?.name }}</div>
            <div class="font-size-14 font-size-md-16 grey-7--text ellip mb-6">{{ location?.address }}</div>
            <div class="row-bar-list-wrap">
                <v-row class="row-bar-list font-size-14">
                    <v-col cols="auto">
                        <div class="d-flex align-center">
                            <span class="grey-2--text mr-4">후기</span>
                            <span class="grey-a--text">{{ location?._reviews?.length || 0 }}</span>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-divider />
    </v-card>
</template>

<script>
export default {
    props: {
        location: { type: Object, default: () => ({}) },
    },
    computed: {
        _location() {
            return this.location?._id;
        },
        lng() {
            return this.location?.geolocation?.coordinates?.[0];
        },
        lat() {
            return this.location?.geolocation?.coordinates?.[1];
        },
    },
    methods: {
        toggleItem() {
            const { _location, lng, lat } = this;
            if (this.$route.query._location == this._location) {
                this.$router.push({ query: { ...this.$route.query, tab: undefined, _location: undefined } });
            } else {
                this.$router.push({ query: { ...this.$route.query, lng, lat, _location, tab: "reviews" } });
            }
        },
    },
};
</script>

<style></style>
