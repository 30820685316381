var render = function render(){
  var _vm$value, _vm$value$meta, _vm$value2, _vm$value2$meta, _vm$value3, _vm$value3$meta, _vm$value4, _vm$value4$meta;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$value = _vm.value) !== null && _vm$value !== void 0 && (_vm$value$meta = _vm$value.meta) !== null && _vm$value$meta !== void 0 && _vm$value$meta.isBest || (_vm$value2 = _vm.value) !== null && _vm$value2 !== void 0 && (_vm$value2$meta = _vm$value2.meta) !== null && _vm$value2$meta !== void 0 && _vm$value2$meta.hasReceipt ? _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, [(_vm$value3 = _vm.value) !== null && _vm$value3 !== void 0 && (_vm$value3$meta = _vm$value3.meta) !== null && _vm$value3$meta !== void 0 && _vm$value3$meta.isBest ? _c('v-chip', {
    staticClass: "font-weight-medium",
    attrs: {
      "color": "red",
      "text-color": "white"
    }
  }, [_vm._v("Best 리뷰")]) : _vm._e(), (_vm$value4 = _vm.value) !== null && _vm$value4 !== void 0 && (_vm$value4$meta = _vm$value4.meta) !== null && _vm$value4$meta !== void 0 && _vm$value4$meta.hasReceipt ? _c('v-chip', {
    staticClass: "font-weight-medium",
    attrs: {
      "color": "secondary",
      "text-color": "white"
    }
  }, [_vm._v("영수증 인증")]) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }