<template>
    <v-expand-transition>
        <div v-show="showsDetail" class="mw-400px mt-16 mt-md-20">
            <v-row no-gutters>
                <v-col v-for="criterion in criteria" :key="criterion.value" cols="6" align="center">
                    <v-row no-gutters align="center" class="mt-6 mt-sm-4">
                        <v-col cols="auto" class="font-size-14 text-left">
                            <div class="w-80px">
                                <b v-if="criterion.bold" class="grey-2--text">{{ criterion.text }}</b>
                                <span v-else class="grey-7--text">{{ criterion.text }}</span>
                            </div>
                        </v-col>
                        <v-col cols="auto">
                            <v-rating :value="value?.stars?.[criterion.value]" readonly half-increments dense color="warning" background-color="warning" size="16" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-expand-transition>
</template>

<script>
import { CRITERIA } from "@/assets/variables";
let criteria = [{ text: "총점", value: "total", bold: true }, ...Object.values(CRITERIA)];
criteria = [criteria[0], criteria[3], criteria[1], criteria[4], criteria[2], criteria[5]];
export default {
    props: {
        value: { type: Object, default: () => ({}) },
        showsDetail: { type: Boolean, default: false },
    },
    data: () => ({
        criteria,
    }),
};
</script>

<style></style>
