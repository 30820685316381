var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip-group', {
    attrs: {
      "value": _vm.keyword,
      "column": ""
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('v-chip', {
      key: item,
      attrs: {
        "value": item,
        "to": `/map/reviews/${item}`,
        "large": "",
        "outlined": "",
        "color": "grey-2"
      }
    }, [_vm._v(_vm._s(item))]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }