<template>
    <v-chip-group v-if="value?.meta?.isBest || value?.meta?.hasReceipt" column>
        <v-chip v-if="value?.meta?.isBest" color="red" text-color="white" class="font-weight-medium">Best 리뷰</v-chip>
        <v-chip v-if="value?.meta?.hasReceipt" color="secondary" text-color="white" class="font-weight-medium">영수증 인증</v-chip>
    </v-chip-group>
</template>

<script>
export default {
    props: { value: { type: Object, default: () => ({}) } },
};
</script>

<style></style>
