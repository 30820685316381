<template>
    <div>
        <v-sheet class="map-drawer__inner">
            <v-fade-transition leave-absolute>
                <v-overlay v-show="!location" absolute z-index="0" color="white" light>
                    <v-progress-circular indeterminate size="100" width="5" color="primary" />
                </v-overlay>
            </v-fade-transition>

            <v-fade-transition>
                <div v-show="_location && location">
                    <div class="px-20 py-16 mt-10 white d-flex justify-center" style="position: sticky; top: 0; z-index: 1;">
                        <v-btn text class="font-size-24 font-weight-bold text-center gret-2--text" :to="`/locations/${location?._id}`">{{ location?.name }}</v-btn>
                    </div>
                    <div class="py-10 pt-md-10 pb-md-18">
                        <v-bottom-navigation grow class="elevation-0">
                            <v-btn text height="auto" class="v-btn--none-active-bg" @click="share">
                                <span class="font-size-14 font-size-md-16 grey-2--text pt-8">공유하기</span>
                                <i class="icon icon-open-in-new"></i>
                            </v-btn>
                            <v-btn text height="auto" class="v-btn--none-active-bg" :href="`tel:+${location?.phone}`" :disabled="!location?.phone">
                                <span class="font-size-14 font-size-md-16 grey-2--text pt-8">전화하기</span>
                                <i class="icon icon-phone-outline"></i>
                            </v-btn>
                        </v-bottom-navigation>
                    </div>
                    <v-divider class="border-8 grey-eb" />
                    <v-divider style="position: sticky; top: 62px; z-index: 10;" />
                    <v-tabs :value="$route.fullPath" ref="tabs" fixed-tabs class="tab tab--underline" style="position: sticky; top: 62px; z-index: 1;">
                        <v-tab :to="`${$route.path}?_location=${_location}&tab=content`" exact><span>홈</span></v-tab>
                        <v-tab :to="`${$route.path}?_location=${_location}&tab=reviews`" exact><span>후기</span></v-tab>
                    </v-tabs>
                    <v-tabs-items :value="tab">
                        <desc-content v-bind="{ location }" />
                        <desc-reviews v-bind="{ location }" />
                    </v-tabs-items>
                    <v-btn v-if="hasLoggedIn" large absolute right bottom fab color="primary" class="rounded-lg" :to="`/reviews/write?_location=${_location}`">
                        <v-icon color="white">mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </v-fade-transition>
        </v-sheet>
    </div>
</template>

<script>
import api from "@/api";
import DescContent from "./desc-content.vue";
import DescReviews from "./desc-reviews.vue";
export default {
    components: {
        DescContent,
        DescReviews,
    },
    data: () => ({
        location: null,
    }),
    computed: {
        _location() {
            return this.$route.query._location;
        },
        tab() {
            return this.$route.query.tab;
        },
        hasLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
    },
    mounted() {
        if (this._location) this.init();
    },
    watch: {
        _location() {
            if (this._location) this.init();
        },
    },
    methods: {
        init() {
            this.location = null;
            this.searchHospital();
        },
        async searchHospital() {
            const { _location: _id } = this;
            const { location } = await api.v1.eyes.locations.get({ _id });
            this.location = location;
            this.$nextTick(() => this.$refs.tabs.callSlider());
        },
        share() {
            const { phone, address1, address2 } = this.location || {};
            const title = `아이리뷰 - ${this.location?.name}`;
            let text = "";
            if (phone) text += `${phone} / `;
            if (address1) text += `${address1}`;
            if (address2) text += ` ${address2}`;
            const url = `${window.location.origin}/locations/${this.location._id}`;

            navigator.share({ title, text, url });
        },
    },
};
</script>

<style></style>
