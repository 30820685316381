<template>
    <v-row no-gutters align="center">
        <v-col cols="auto">
            <div class="d-flex align-center">
                <span class="font-size-14 font-size-md-16 primary--text">{{ SERVICES[value?.services?.[0]]?.text }} {{ serviceCount }}</span>
                <span class="font-size-12 font-size-md-14 grey-a--text ml-6">{{ value?.writer?.nickname }}님</span>
            </div>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
            <v-row align="center" class="row--small">
                <v-col cols="auto">
                    <review-like-button v-bind="{ review: value }" iconEmptyClass="icon-heart-outline-grey" iconFilledClass="icon-heart-primary" textEmptyClass="font-size-14 font-weight-regular grey-7--text ml-4" textFilledClass="font-size-14 font-weight-regular grey-7--text ml-4" />
                </v-col>
                <v-col cols="auto">
                    <div class="d-flex align-center">
                        <i class="icon icon-comment-outline-grey"></i>
                        <span class="font-size-14 grey-7--text ml-4">{{ commentsLength }}</span>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { SERVICES } from "@/assets/variables";
import reviewLikeButton from "@/components/dumb/review-like-button.vue";
export default {
    components: { reviewLikeButton },
    props: {
        value: { type: Object, default: () => ({}) },
        commentsLength: { type: Number, default: 0 },
    },
    data: () => ({
        SERVICES,
    }),
    computed: {
        serviceCount() {
            const { length } = this.value?.services || [];
            if (length > 1) return `외 ${length - 1}`;
            return "";
        },
    },
};
</script>

<style></style>
