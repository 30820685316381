<template>
    <v-btn-toggle v-model="sort" mandatory borderless active-class="active">
        <v-btn value="createdAt" text x-small class="v-btn--dot">최신순</v-btn>
        <v-btn value="meta.likes" text x-small class="v-btn--dot">추천순</v-btn>
        <v-btn value="_comments" text x-small class="v-btn--dot">댓글순</v-btn>
    </v-btn-toggle>
</template>

<script>
export default {
    data: () => ({
        sort: "createdAt",
    }),
    computed: {
        mode() {
            return this.$route.params.mode;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query.sort"() {
            this.sync();
        },
        sort() {
            if (this.mode == "reviews") {
                const { sort } = this;
                if (sort !== this.$route.query.sort) this.$router.push({ query: { ...this.$route.query, sort } });
            }
        },
    },
    methods: {
        sync() {
            this.sort = this.$route.query.sort || "createdAt";
        },
    },
};
</script>

<style></style>
