var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', [_c('div', {
    staticClass: "hospital-image-banner"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.images, function (src, index) {
    return _c('div', {
      key: src,
      staticClass: "swiper-slide"
    }, [_c('imgs-dialog', {
      attrs: {
        "imgs": _vm.images
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var attrs = _ref.attrs,
            on = _ref.on,
            setIndex = _ref.setIndex;
          return [_c('v-card', {
            staticClass: "rounded ma-auto",
            attrs: {
              "flat": ""
            }
          }, [_c('v-img', _vm._g(_vm._b({
            attrs: {
              "aspect-ratio": 1 / 1
            },
            on: {
              "click": function ($event) {
                return setIndex(index);
              }
            }
          }, 'v-img', Object.assign({}, attrs, {
            src
          }), false), on))], 1)];
        }
      }], null, true)
    })], 1);
  }), 0)]), _vm._m(1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-18 font-weight-bold grey-2--text"
  }, [_vm._v("후기사진")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "swiper-control"
  }, [_c('div', {
    staticClass: "swiper-prev"
  }, [_c('i', {
    staticClass: "icon icon-chevron-left"
  })]), _c('div', {
    staticClass: "swiper-next"
  }, [_c('i', {
    staticClass: "icon icon-chevron-right"
  })])]);

}]

export { render, staticRenderFns }