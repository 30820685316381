<template>
    <v-sheet height="100%" style="position:relative">
        <map-sheet-kakaomap v-bind="{ lat, lng, lvl, maxLevel: 5, locations: clusters, locationsWithoutReviews }" @search="search" />
        <map-sheet-actions>
            <actions-current @search="search" />
            <actions-area @search="search" />
        </map-sheet-actions>
        <actions-search v-if="showsSearch" v-bind="{ lat, lng, lvl, queryLat, queryLng, queryLvl }" @search="search" />
        <svg style="width:0;height:0;position:absolute;" aria-hidden="true" focusable="false">
            <linearGradient id="gradient" x1="0" y1="0">
                <stop offset="0%" stop-color="#999" />
                <stop offset="100%" stop-color="#bababa" />
            </linearGradient>
        </svg>
        <svg style="width:0;height:0;position:absolute;" aria-hidden="true" focusable="false">
            <linearGradient id="gradient2" x1="0" y1="0">
                <stop offset="0%" stop-color="#56beef" />
                <stop offset="100%" stop-color="#78dcff" />
            </linearGradient>
        </svg>
        <svg style="width:0;height:0;position:absolute;" aria-hidden="true" focusable="false">
            <linearGradient id="gradient3" x1="0" y1="0">
                <stop offset="0%" stop-color="#50bef0" />
                <stop offset="100%" stop-color="#80e8ff" />
            </linearGradient>
        </svg>
        <svg style="width:0;height:0;position:absolute;" aria-hidden="true" focusable="false">
            <linearGradient id="gradient4" x1="0" y1="0">
                <stop offset="0%" stop-color="#4591ff" />
                <stop offset="100%" stop-color="#82cfff" />
            </linearGradient>
        </svg>
        <svg style="width:0;height:0;position:absolute;" aria-hidden="true" focusable="false">
            <linearGradient id="gradient5" x1="0" y1="0">
                <stop offset="0%" stop-color="#2c50ff" />
                <stop offset="100%" stop-color="#2cbaff" />
            </linearGradient>
        </svg>
        <pc-mode-alert />
    </v-sheet>
</template>

<script>
import api from "@/api";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import PcModeAlert from "./pc-mode-alert.vue";
import ActionsArea from "./actions-area.vue";
import ActionsSearch from "./actions-search.vue";
import ActionsCurrent from "./actions-current.vue";
import MapSheetActions from "./map-sheet-actions.vue";
import MapSheetKakaomap from "./map-sheet-kakaomap.vue";

export default {
    components: {
        PcModeAlert,
        ActionsArea,
        ActionsSearch,
        ActionsCurrent,
        MapSheetActions,
        MapSheetKakaomap,
    },
    data: () => ({
        clusters: [],
        locationsWithoutReviews: [],
        showsSearch: true,
    }),
    computed: {
        ...mapState("location", ["lat", "lng", "lvl", "bounds"]),
        ...mapGetters("location", ["geolocation"]),
        queryLat() {
            return +this.$route.query.lat || null;
        },
        queryLng() {
            return +this.$route.query.lng || null;
        },
        queryLvl() {
            return +this.$route.query.lvl || null;
        },
        keyword() {
            return this.$route.params.keyword;
        },
        isSynced() {
            return this.$route.query.isSynced;
        },
        _location() {
            return this.$route.query._location;
        },
        areaKeyword() {
            const regexp = /^＃([\S]+)안과/;
            if (regexp.test(this.keyword || "")) return this.keyword.match(regexp)[1];
        },
    },
    mounted() {
        if (!!this.queryLat && !!this.queryLng) {
            const lat = this.queryLat;
            const lng = this.queryLng;
            const lvl = this.queryLvl || 3;

            this.setLvl({ lvl });
            this.setCoordinates({ lat, lng });
        } else if (!!this.areaKeyword) {
            this.getAddressLocation({
                sido: this.areaKeyword,
                callback: () => {
                    this.setLvl({ lvl: 5 });
                    this.$nextTick(this.search);
                },
            });
        } else this.getCurrentLocation();
    },
    destroyed() {
        this.setBounds();
    },
    watch: {
        areaKeyword() {
            if (!!this.areaKeyword) {
                this.getAddressLocation({
                    sido: this.areaKeyword,
                    callback: () => {
                        this.setLvl({ lvl: 5 });
                        this.$nextTick(this.search);
                    },
                });
            }
        },
        _location(_location) {
            if (!!_location && !this.isSynced) this.$nextTick(this.search);
        },
    },

    methods: {
        ...mapMutations("location", ["setCoordinates", "setLvl", "setBounds"]),
        ...mapActions("location", ["getCurrentLocation", "getSidoGugun", "getAddressLocation"]),
        async search({ withoutRouting = false, isSynced = false } = {}) {
            this.showsSearch = false;

            // Routing
            const { lat, lng, lvl } = this;
            if (!withoutRouting) this.$router.push({ query: { ...this.$route.query, lat, lng, lvl } });
            this.getSidoGugun({ lng, lat });

            // clusters Get
            const { bounds, geolocation: near } = this;
            const { clusters, locationsWithoutReviews } = await api.v1.eyes.map.getReviewClusters({ params: { bounds, near } });
            this.clusters = clusters;
            this.locationsWithoutReviews = locationsWithoutReviews;

            this.$nextTick(() => {
                this.showsSearch = true;
                if (isSynced) {
                    const query = { ...this.$route.query, isSynced };
                    delete query._location;
                    this.$router.push({ query });
                }
            });
        },
    },
};
</script>

<style></style>
