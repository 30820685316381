var render = function render(){
  var _vm$location, _vm$location2, _vm$location3, _vm$location4, _vm$location5, _vm$location5$_review, _vm$location6, _vm$location6$meta, _vm$location6$meta$st, _vm$images;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": "content"
    }
  }, [_c('div', {
    staticClass: "px-20 py-16 px-md-24 py-md-20"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "mr-10"
  }, [_c('i', {
    staticClass: "icon icon-phone-outline-sm"
  })]), _c('span', {
    staticClass: "font-size-14 font-size-md-16 grey-2--text"
  }, [_vm._v(_vm._s((_vm$location = _vm.location) === null || _vm$location === void 0 ? void 0 : _vm$location.phone))])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "mr-10"
  }, [_c('i', {
    staticClass: "icon icon-map-marker-outline-sm",
    staticStyle: {
      "width": "16px"
    }
  })]), _c('span', {
    staticClass: "font-size-14 font-size-md-16 grey-2--text"
  }, [_vm._v(_vm._s((_vm$location2 = _vm.location) === null || _vm$location2 === void 0 ? void 0 : _vm$location2.address))])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "mr-10"
  }, [_c('i', {
    staticClass: "icon icon-home-outline-sm"
  })]), _c('a', {
    staticClass: "font-size-14 font-size-md-16 primary--text",
    attrs: {
      "href": (_vm$location3 = _vm.location) === null || _vm$location3 === void 0 ? void 0 : _vm$location3.homepage,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s((_vm$location4 = _vm.location) === null || _vm$location4 === void 0 ? void 0 : _vm$location4.homepage))])])])], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "px-20 py-16 px-md-24 py-md-20"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "mr-10"
  }, [_c('i', {
    staticClass: "icon icon-comment-sm"
  })]), _c('span', {
    staticClass: "font-size-14 font-size-md-16 grey-2--text mr-6"
  }, [_vm._v("후기")]), _c('span', {
    staticClass: "font-size-14 font-size-md-16 grey-a--text"
  }, [_vm._v(_vm._s((_vm$location5 = _vm.location) === null || _vm$location5 === void 0 ? void 0 : (_vm$location5$_review = _vm$location5._reviews) === null || _vm$location5$_review === void 0 ? void 0 : _vm$location5$_review.length) + "개")])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-rating', {
    attrs: {
      "value": (((_vm$location6 = _vm.location) === null || _vm$location6 === void 0 ? void 0 : (_vm$location6$meta = _vm$location6.meta) === null || _vm$location6$meta === void 0 ? void 0 : (_vm$location6$meta$st = _vm$location6$meta.starsAverage) === null || _vm$location6$meta$st === void 0 ? void 0 : _vm$location6$meta$st.total) || 0) / 10,
      "readonly": "",
      "half-increments": "",
      "color": "warning",
      "background-color": "warning",
      "dense": ""
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "px-20 py-16 px-md-24 py-md-20"
  }, [_c('hospital-services', _vm._b({}, 'hospital-services', {
    location: _vm.location
  }, false))], 1), ((_vm$images = _vm.images) === null || _vm$images === void 0 ? void 0 : _vm$images.length) > 0 ? [_c('v-divider', {
    staticClass: "border-8 grey-eb"
  }), _c('div', {
    staticClass: "px-20 py-16 px-md-24 py-md-20 hidden"
  }, [_c('hospital-images', _vm._b({}, 'hospital-images', {
    images: _vm.images
  }, false))], 1)] : _vm._e(), _c('v-divider', {
    staticClass: "border-8 grey-eb"
  }), _c('hospital-reviews', _vm._b({}, 'hospital-reviews', {
    location: _vm.location
  }, false))], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }