var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "px-20 py-16 px-md-24 py-md-30",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    staticClass: "mb-8 mb-md-18"
  }, [_c('reviews-item-chips', _vm._b({}, 'reviews-item-chips', {
    value: _vm.value
  }, false))], 1), _c('div', {
    staticClass: "mb-6 mb-md-12"
  }, [_c('reviews-item-basic', _vm._b({}, 'reviews-item-basic', {
    value: _vm.value,
    commentsLength: _vm.commentsLength
  }, false))], 1), _c('reviews-item-texts', _vm._b({
    on: {
      "showsDetail": function (bool) {
        return _vm.showsDetail = bool;
      }
    }
  }, 'reviews-item-texts', {
    value: _vm.value,
    showsDetail: _vm.showsDetail,
    isLoggedIn: _vm.isLoggedIn
  }, false)), _c('div', {
    class: {
      blurred: !_vm.isLoggedIn
    }
  }, [_c('reviews-item-stars', _vm._b({}, 'reviews-item-stars', {
    value: _vm.value,
    showsDetail: _vm.showsDetail
  }, false)), _c('reviews-item-doctor', _vm._b({}, 'reviews-item-doctor', {
    value: _vm.value,
    showsDetail: _vm.showsDetail
  }, false)), _c('reviews-item-charges', _vm._b({}, 'reviews-item-charges', {
    value: _vm.value,
    showsDetail: _vm.showsDetail
  }, false))], 1), _c('div', {
    staticClass: "mt-20 mt-md-30"
  }, [_vm.showsDetail && _vm._review ? _c('review-item-comments', _vm._b({
    on: {
      "syncComments": function (num) {
        return _vm.commentsLength = num;
      }
    }
  }, 'review-item-comments', {
    review: _vm.value,
    _review: _vm._review
  }, false)) : _vm._e()], 1), !_vm.isLoggedIn ? _c('v-btn', {
    staticStyle: {
      "right": "50%",
      "bottom": "2rem",
      "transform": "translateX(50%)"
    },
    attrs: {
      "dark": "",
      "color": "grey",
      "absolute": "",
      "to": "/login"
    }
  }, [_vm._v("로그인 후 확인하기")]) : _vm._e()], 1), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }