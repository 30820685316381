<template>
    <div class="row-bar-list-wrap">
        <v-row class="row-bar-list font-size-14">
            <v-col v-for="item in services" :key="item.value" cols="auto">
                <div class="d-flex align-center">
                    <span class="grey-2--text mr-4">{{ item.text }}</span>
                    <span class="grey-a--text">{{ location?.meta?.reviewsCount?.[item.value] || 0 }}</span>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { SERVICES } from "@/assets/variables";
const services = Object.values(SERVICES);
export default {
    props: {
        location: { type: Object, default: () => ({}) },
    },
    data: () => ({
        services,
    }),
};
</script>

<style></style>
