<template>
    <v-card @click="toggleItem" flat>
        <div class="pa-20 px-md-30 py-md-26">
            <v-row no-gutters>
                <v-col>
                    <div class="font-size-20 font-size-md-24 font-weight-bold primary--text mb-8">{{ serviceName }}</div>
                    <div class="font-size-14 grey-a--text mb-8">{{ review?.writer?.nickname }}님</div>
                    <div>
                        <v-row align="center" class="row--small">
                            <v-col cols="auto">
                                <div class="d-flex align-center">
                                    <i class="icon icon-heart-outline-grey"></i>
                                    <span class="font-size-14 grey-7--text ml-4">{{ review?.meta?.likes || 0 }}</span>
                                </div>
                            </v-col>
                            <v-col cols="auto">
                                <div class="d-flex align-center">
                                    <i class="icon icon-comment-outline-grey"></i>
                                    <span class="font-size-14 grey-7--text ml-4">{{ review?._comments?.length || 0 }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="auto">
                    <imgs-dialog :imgs="images" :disabled="!isLoggedIn">
                        <template #activator="{ attrs, on }">
                            <v-img v-bind="attrs" v-on="on" :src="images?.[0]" class="rounded" width="90" :aspect-ratio="1 / 1" style="overflow: hidden" content-class="d-flex justify-end align-end">
                                <v-overlay slot="placeholder" absolute z-index="0" color="grey lighten-2">
                                    <v-icon x-large color="grey">mdi-image-off-outline</v-icon>
                                </v-overlay>

                                <v-card width="26" height="26" tile dark color="rgba(0,0,0,0.5)" class="d-flex align-center justify-center" style="border-radius: 10px 0px !important">
                                    <span class="font-size-12 font-weight-light">{{ images?.length }}</span>
                                </v-card>
                            </v-img>
                        </template>
                    </imgs-dialog>
                </v-col>
            </v-row>
            <div class="mt-10 mt-md-18">
                <div class="mb-6 mb-md-8 ellip">
                    <strong class="font-size-16 font-size-md-18 grey-2--text">{{ review.subject }}</strong>
                </div>
                <div :class="{ 'font-size-14 font-size-md-16 grey-7--text ellipsis': true, blurred: !isLoggedIn }">{{ content }}</div>
            </div>
            <div class="mt-10 mt-md-14">
                <v-row no-gutters align="end">
                    <v-col cols="auto">
                        <div class="font-size-14 grey-a--text">{{ review.location?.areaName }}</div>
                        <div class="d-flex align-center">
                            <span class="font-size-14 font-size-md-16 font-weight-bold grey-2--text">{{ review.location?.name }}</span>
                            <v-icon class="font-size-md-18 grey-2--text mt-n2 ml-4">mdi-chevron-right</v-icon>
                        </div>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-rating :value="review?.stars?.total" readonly half-increments dense color="warning" background-color="warning" />
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-divider />
    </v-card>
</template>

<script>
import { SERVICES } from "@/assets/variables";
import imgsDialog from "@/components/dumb/imgs-dialog.vue";

export default {
    components: { imgsDialog },
    props: {
        review: { type: Object, default: () => ({}) },
    },
    computed: {
        serviceName() {
            let name = SERVICES[this.review?.services?.[0]]?.text;
            let { length } = this.review?.services || [];
            length -= 1;
            if (length > 0) name += ` 외 ${length}`;
            return name;
        },
        content() {
            return this.review?.content?.replace?.(/(<([^>]+)>)/gi, "")?.replace?.(/&nbsp;/gi, " ");
        },
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
        _location() {
            return this.review?._location;
        },
        lng() {
            return this.review?.location?.geolocation?.coordinates?.[0];
        },
        lat() {
            return this.review?.location?.geolocation?.coordinates?.[1];
        },
        images() {
            const { photos = [], images = [] } = this.review || {};
            if (photos.length) return photos.map(({ url }) => url);
            else return images;
        },
    },
    methods: {
        toggleItem() {
            const { _location, lng, lat } = this;
            if (this.$route.query._location == this._location) {
                this.$router.push({ query: { ...this.$route.query, tab: undefined, _location: undefined } });
            } else {
                this.$router.push({ query: { ...this.$route.query, _location, lng, lat, tab: "content" } });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.ellipsis {
    max-height: 3.2em;
    line-height: 1.6;
}

::v-deep .blurred {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    user-select: none;
}
</style>
