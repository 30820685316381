<template>
    <v-tab-item value="reviews">
        <div class="px-20 py-16 px-md-24 py-md-20">
            <hospital-services v-bind="{ location }" />
        </div>

        <template v-if="images?.length > 0">
            <v-divider class="border-8 grey-eb" />
            <div class="px-20 py-16 px-md-24 py-md-20 hidden">
                <hospital-images v-bind="{ images }" />
            </div>
        </template>

        <v-divider class="border-8 grey-eb" />
        <hospital-reviews v-bind="{ location }" />
    </v-tab-item>
</template>

<script>
import HospitalImages from "./hospital-images.vue";
import HospitalReviews from "./hospital-reviews.vue";
import HospitalServices from "./hospital-services.vue";
export default {
    components: {
        HospitalImages,
        HospitalReviews,
        HospitalServices,
    },
    props: {
        location: { type: Object, default: () => ({}) },
    },
    computed: {
        images() {
            return this.location?.images;
        },
    },
};
</script>

<style></style>
