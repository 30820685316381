<template>
    <client-page hideFooter isMap class="app--map">
        <map-sheet slot="map" class="map-content" />
        <map-drawer slot="drawer" />
    </client-page>
</template>

<script>
import MapSheet from "@/components/client/map-sheet/map-sheet.vue";
import MapDrawer from "@/components/client/map-drawer/map-drawer.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
export default {
    components: {
        MapSheet,
        MapDrawer,
        ClientPage,
    },
    props: {
        mode: { type: String, default: "hospitals" },
        keyword: { type: String, default: null },
    },
    data: () => ({
        showsDrawer: true,
        showHospital: false,
    }),
    mounted() {
        this.syncRoute();
    },
    watch: {
        "$route.path"() {
            this.syncRoute();
        },
    },
    methods: {
        syncRoute() {
            const { query } = this.$route;
            let path = `/map/${this.mode}`;
            if (this.keyword) path += `/${this.keyword}`;
            if (this.$route.path !== path) this.$router.replace({ path, query });
        },
    },
};
</script>

<style scoped>
.map-content {
    height: 70vh !important;
    padding-top: var(--header-body);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .map-content {
        height: 100vh !important;
    }
}
</style>
