<template>
    <v-row no-gutters>
        <v-col class="mr-10">
            <v-select v-model="sido" :items="sidos" placeholder="시/도" outlined hide-details dense clearable @input="gugun = null" />
        </v-col>
        <v-col>
            <v-select v-model="gugun" :items="guguns" placeholder="시/군/구" outlined hide-details dense clearable>
                <div slot="no-data" class="pa-10">먼저 시/도를 선택하세요.</div>
            </v-select>
        </v-col>
    </v-row>
</template>

<script>
import sidoGugun from "@/assets/data/sido-gugun.json";
import { mapActions, mapState } from "vuex";
const sidos = sidoGugun.map(({ name }) => name);

export default {
    props: {
        callback: { type: Function, default: () => {} },
    },
    data: () => ({
        sido: null,
        gugun: null,

        sidos,
    }),
    computed: {
        ...mapState(["location"]),
        guguns() {
            return (sidoGugun.find(({ name }) => name == this.sido)?.gugun || []).map(({ name }) => name);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        "location.sido"() {
            this.sync();
        },
        "location.gugun"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.sido = this.location.sido || null;
            this.gugun = this.location.gugun || null;
        },
    },
};
</script>
