<template>
    <div ref="element" :style="{ width, height }">
        <slot v-if="ready" :on="{ setSelectedMarker, setClickedOverlay }" :attrs="{ selectedMarker, clickedOverlay }" />
    </div>
</template>

<script>
export default {
    props: {
        width: { type: String, default: "500px" },
        height: { type: String, default: "400px" },

        centerPosition: { type: Object, default: null },
        centerAddress: { type: String, default: null },
        level: { type: Number, default: 3 },
        maxLevel: { type: Number, default: null },
        draggable: { type: Boolean, default: true },
        scrollwheel: { type: Boolean, default: true },
        keyboardShortcuts: { type: Boolean, default: false },
        disableDoubleClick: { type: Boolean, default: false },
        disableDoubleClickZoom: { type: Boolean, default: false },

        onZoomChanged: { type: Function, default: () => () => {} },
        onBoundsChanged: { type: Function, default: () => () => {} },
        onCenterChanged: { type: Function, default: () => () => {} },

        usesCustomMarker: { type: Boolean, default: false },

        customMarkerProps: {
            type: Object,
            default: () => ({
                MARKER_WIDTH: 23, // 기본 마커의 너비
                MARKER_HEIGHT: 23, // 기본 마커의 높이
                OFFSET_X: 11, // 기본 마커의 기준 X좌표
                OFFSET_Y: 11, // 기본 마커의 기준 Y좌표
                CLICK_MARKER_WIDTH: 32, // 클릭 마커의 너비
                CLICK_MARKER_HEIGHT: 36, // 클릭 마커의 높이
                CLICK_OFFSET_X: 16, // 클릭 마커의 기준 X좌표
                CLICK_OFFSET_Y: 36, // 클릭 마커의 기준 Y좌표
                OVER_MARKER_WIDTH: 23, // 오버 마커의 너비
                OVER_MARKER_HEIGHT: 23, // 오버 마커의 높이
                OVER_OFFSET_X: 11, // 오버 마커의 기준 X좌표
                OVER_OFFSET_Y: 11, // 오버 마커의 기준 Y좌표
                SPRITE_MARKER_URL: "/images/map/pin-all@2x.png", // 스프라이트 마커 이미지 URL
                SPRITE_WIDTH: 69, // 스프라이트 이미지 너비
                SPRITE_HEIGHT: 186, // 스프라이트 이미지 높이
                SPRITE_GAP: 14, // 스프라이트 이미지에서 마커간 간격
            }),
        },
    },
    data() {
        return {
            ready: false,

            map: null,
            geocoder: null,

            center: Object.assign({ lat: 33.450701, lng: 126.570667 }, this.centerPosition),

            selectedMarker: null,
            clickedOverlay: null,
        };
    },
    computed: {
        customMarkerComputed() {
            const { MARKER_WIDTH, MARKER_HEIGHT, OFFSET_X, OFFSET_Y, CLICK_MARKER_WIDTH, CLICK_MARKER_HEIGHT, CLICK_OFFSET_X, CLICK_OFFSET_Y, OVER_MARKER_WIDTH, OVER_MARKER_HEIGHT, OVER_OFFSET_X, OVER_OFFSET_Y, SPRITE_WIDTH, SPRITE_HEIGHT, SPRITE_GAP } = this.customMarkerProps;

            return {
                markerSize: new kakao.maps.Size(MARKER_WIDTH, MARKER_HEIGHT), // 기본, 클릭 마커의 크기
                markerOffset: new kakao.maps.Point(OFFSET_X, OFFSET_Y), // 기본, 클릭 마커의 기준좌표
                clickMarkerSize: new kakao.maps.Size(CLICK_MARKER_WIDTH, CLICK_MARKER_HEIGHT), // 클릭 마커의 크기
                clickMarkerOffset: new kakao.maps.Point(CLICK_OFFSET_X, CLICK_OFFSET_Y), // 클릭 마커의 기준좌표
                overMarkerSize: new kakao.maps.Size(OVER_MARKER_WIDTH, OVER_MARKER_HEIGHT), // 오버 마커의 크기
                overMarkerOffset: new kakao.maps.Point(OVER_OFFSET_X, OVER_OFFSET_Y), // 오버 마커의 기준 좌표
                spriteImageSize: new kakao.maps.Size(SPRITE_WIDTH, SPRITE_HEIGHT), // 스프라이트 이미지의 크기}

                gapX: MARKER_WIDTH + SPRITE_GAP, // 스프라이트 이미지에서 마커로 사용할 이미지 X좌표 간격 값
                originY: MARKER_HEIGHT + SPRITE_GAP, // 스프라이트 이미지에서 기본 마커로 사용할 Y좌표 값
                clickOriginY: CLICK_MARKER_HEIGHT + SPRITE_GAP, // 스프라이트 이미지에서 클릭 마커로 사용할 Y좌표 값
                overOriginY: OVER_MARKER_HEIGHT + SPRITE_GAP, // 스프라이트 이미지에서 오버 마커로 사용할 Y좌표 값
            };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        centerPosition(value) {
            this.center = value;
        },
        centerAddress(value) {
            if (value)
                this.searchAddress(value).then((position) => {
                    this.center = position;
                });
        },
        center(value) {
            var position = Object.assign({ lat: 33.450701, lng: 126.570667 }, value);
            if (this.map) this.map.setCenter(new kakao.maps.LatLng(position.lat, position.lng));
        },
        level(value) {
            if (this.map) this.map.setLevel(value);
        },
        maxLevel(value) {
            if (this.map) this.map.setMaxLevel(value);
        },
    },
    methods: {
        async init() {
            const { draggable, scrollwheel, keyboardShortcuts, disableDoubleClick, disableDoubleClickZoom } = this;
            //////////////////////////////////////////////////////////////////////////////////
            // 카카오맵 인스턴스 초기화
            //////////////////////////////////////////////////////////////////////////////////
            var element = this.$refs.element; //지도를 담을 영역의 DOM 레퍼런스
            var options = {
                //지도를 생성할 때 필요한 기본 옵션
                center: new kakao.maps.LatLng(this.center.lat, this.center.lng), //지도의 중심좌표.
                level: this.level, //지도의 레벨(확대, 축소 정도)
                draggable,
                scrollwheel,
                keyboardShortcuts,
                disableDoubleClick,
                disableDoubleClickZoom,
            };

            this.map = new kakao.maps.Map(element, options); //지도 생성 및 객체 리턴
            this.map.setMaxLevel(this.maxLevel);
            this.geocoder = new kakao.maps.services.Geocoder();

            // 이벤트 추가
            kakao.maps.event.addListener(this.map, "zoom_changed", this.onZoomChanged);
            kakao.maps.event.addListener(this.map, "bounds_changed", this.onBoundsChanged);
            kakao.maps.event.addListener(this.map, "center_changed", this.onCenterChanged);

            if (this.centerAddress) {
                this.searchAddress(this.centerAddress).then((position) => {
                    this.center = position;
                });
            }

            this.$nextTick(() => {
                this.ready = true;
            });

            // 카카오맵 클릭 이벤트 등록
            this.addClickEventListener(({ position, address }) => {
                this.$emit("click", { position, address });
                if (this.selectedMarker) this.selectedMarker.setImage(this.selectedMarker.image_normal);
                if (this.clickedOverlay) this.clickedOverlay.setMap(null);
            });
        },

        addClickEventListener(callback) {
            kakao.maps.event.addListener(this.map, "click", (mouseEvent) => {
                var position = {
                    lat: mouseEvent.latLng.Ma,
                    lng: mouseEvent.latLng.La,
                };

                var address = null;

                this.geocoder.coord2Address(mouseEvent.latLng.La, mouseEvent.latLng.Ma, (result, status) => {
                    if (status === kakao.maps.services.Status.OK) {
                        address = result[0].address.address_name;
                    }
                    callback({ position, address });
                });
            });
            kakao.maps.event.addListener(this.map, "mousedown", () => {
                this.$children.forEach((child) => (child.$data.showsMenu = false));
            });
        },

        searchAddress(address) {
            return new Promise((resolve) => {
                if (this.geocoder) {
                    this.geocoder.addressSearch(address, (result, status) => {
                        if (status === kakao.maps.services.Status.OK) {
                            resolve({
                                lat: result[0].y,
                                lng: result[0].x,
                            });
                        } else resolve(null);
                    });
                } else resolve(null);
            });
        },
        setSelectedMarker(marker) {
            this.selectedMarker = marker;
        },
        setClickedOverlay(overlay) {
            this.clickedOverlay = overlay;
        },
    },
};
</script>
