var render = function render(){
  var _vm$SERVICES$_vm$valu, _vm$value, _vm$value$services, _vm$value2, _vm$value2$writer;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "font-size-14 font-size-md-16 primary--text"
  }, [_vm._v(_vm._s((_vm$SERVICES$_vm$valu = _vm.SERVICES[(_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$services = _vm$value.services) === null || _vm$value$services === void 0 ? void 0 : _vm$value$services[0]]) === null || _vm$SERVICES$_vm$valu === void 0 ? void 0 : _vm$SERVICES$_vm$valu.text) + " " + _vm._s(_vm.serviceCount))]), _c('span', {
    staticClass: "font-size-12 font-size-md-14 grey-a--text ml-6"
  }, [_vm._v(_vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$writer = _vm$value2.writer) === null || _vm$value2$writer === void 0 ? void 0 : _vm$value2$writer.nickname) + "님")])])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('review-like-button', _vm._b({
    attrs: {
      "iconEmptyClass": "icon-heart-outline-grey",
      "iconFilledClass": "icon-heart-primary",
      "textEmptyClass": "font-size-14 font-weight-regular grey-7--text ml-4",
      "textFilledClass": "font-size-14 font-weight-regular grey-7--text ml-4"
    }
  }, 'review-like-button', {
    review: _vm.value
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('i', {
    staticClass: "icon icon-comment-outline-grey"
  }), _c('span', {
    staticClass: "font-size-14 grey-7--text ml-4"
  }, [_vm._v(_vm._s(_vm.commentsLength))])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }