var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "px-20 py-16 px-md-24 py-md-20"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-18 grey-2--text"
  }, [_vm._v("후기 "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.reviewsLength))])])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('hospital-reviews-sort', {
    staticClass: "mr-n12"
  })], 1)], 1)], 1), _c('v-divider'), _vm._l(_vm.reviews, function (review) {
    return _c('hospital-reviews-item', {
      key: review._id,
      attrs: {
        "value": review
      }
    });
  }), _vm.hasMore ? _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-180px",
    attrs: {
      "x-large": "",
      "outlined": "",
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.loadMore
    }
  }, [_vm._v("더 보기")])], 1)]) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }