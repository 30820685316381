var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isBrowserOnPcMode ? _c('v-sheet', {
    directives: [{
      name: "touch",
      rawName: "v-touch",
      value: {
        start: _vm.start
      },
      expression: "{ start }"
    }],
    staticClass: "map-scheet-pc-mode-alert-sheet d-flex align-center justify-center",
    attrs: {
      "color": "transparent"
    }
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }