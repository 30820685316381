var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn-toggle', {
    attrs: {
      "mandatory": "",
      "borderless": "",
      "active-class": "active"
    },
    model: {
      value: _vm.sortReviews,
      callback: function ($$v) {
        _vm.sortReviews = $$v;
      },
      expression: "sortReviews"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--dot",
    attrs: {
      "value": "createdAt",
      "text": "",
      "x-small": ""
    }
  }, [_vm._v("최신순")]), _c('v-btn', {
    staticClass: "v-btn--dot",
    attrs: {
      "value": "meta.likes",
      "text": "",
      "x-small": ""
    }
  }, [_vm._v("추천순")]), _c('v-btn', {
    staticClass: "v-btn--dot",
    attrs: {
      "value": "_comments",
      "text": "",
      "x-small": ""
    }
  }, [_vm._v("댓글순")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }