<template>
    <v-btn-toggle v-model="sortReviews" mandatory borderless active-class="active">
        <v-btn value="createdAt" text x-small class="v-btn--dot">최신순</v-btn>
        <v-btn value="meta.likes" text x-small class="v-btn--dot">추천순</v-btn>
        <v-btn value="_comments" text x-small class="v-btn--dot">댓글순</v-btn>
    </v-btn-toggle>
</template>

<script>
export default {
    data: () => ({
        sortReviews: "createdAt",
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query.sortReviews"() {
            this.sync();
        },
        sortReviews() {
            const { sortReviews } = this;
            if (sortReviews !== this.$route.query.sortReviews) this.$router.push({ query: { ...this.$route.query, sortReviews } });
        },
    },
    methods: {
        sync() {
            this.sortReviews = this.$route.query.sortReviews || "createdAt";
        },
    },
};
</script>

<style></style>
